import { isNumber } from 'lodash-es'

export const getStrokeWidth = (size: string|number) => {
  if (!size) {
    return '1'
  }

  // @ts-expect-error Type derivation isn't working with isNumber
  const parsed: number = isNumber(size) ? parseInt(size, 10) : size

  if (Number.isNaN(parsed)) {
    return '1'
  }

  return `${Math.max(0.5, parsed / 16)}`
}

