import { DefaultIconProps } from '@/lib/core/interfaces/common'

export const TruckIcon = ({
  color = 'currentColor',
  size = '16',
  strokeWidth = '1',
}: DefaultIconProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.66683 11.3333H9.3335V3.33325H1.3335V11.3333H3.3335"
      stroke={color}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.3335 11.3333H14.6668V9.10667C14.6671 8.75637 14.5984 8.40945 14.4645 8.08573C14.3307 7.76201 14.1344 7.46783 13.8868 7.22L12.6668 6H9.3335"
      stroke={color}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.3335 11.3333H10.0002"
      stroke={color}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.00016 13.3333C5.92064 13.3333 6.66683 12.5871 6.66683 11.6667C6.66683 10.7462 5.92064 10 5.00016 10C4.07969 10 3.3335 10.7462 3.3335 11.6667C3.3335 12.5871 4.07969 13.3333 5.00016 13.3333Z"
      stroke={color}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.6667 13.3333C12.5871 13.3333 13.3333 12.5871 13.3333 11.6667C13.3333 10.7462 12.5871 10 11.6667 10C10.7462 10 10 10.7462 10 11.6667C10 12.5871 10.7462 13.3333 11.6667 13.3333Z"
      stroke={color}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
