export type MapVehicleLostContactIconProps = {
  height?: number
  width?: number
}

export const MapVehicleLostContactIcon = ({ height, width }: MapVehicleLostContactIconProps) => (
  <svg
    width={width ?? '54'}
    height={height ?? '66'}
    viewBox="0 0 54 66"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_d_5954_17128)">
      <path
        d="M51 24C51 42 27 60 27 60C27 60 3 42 3 24C3 17.6348 5.52856 11.5303 10.0294 7.02944C14.5303 2.52856 20.6348 0 27 0C33.3652 0 39.4697 2.52856 43.9706 7.02944C48.4714 11.5303 51 17.6348 51 24Z"
        fill="white"
      />
      <path
        d="M49.5 24C49.5 32.4075 43.8443 41.0424 37.8789 47.7534C34.9314 51.0694 31.9792 53.8376 29.7622 55.7774C28.6548 56.7464 27.7336 57.5063 27.0917 58.0221C27.0604 58.0472 27.0299 58.0717 27 58.0957C26.9701 58.0717 26.9396 58.0472 26.9083 58.0221C26.2664 57.5063 25.3452 56.7464 24.2378 55.7774C22.0208 53.8376 19.0686 51.0694 16.1211 47.7534C10.1557 41.0424 4.5 32.4075 4.5 24C4.5 18.0326 6.87053 12.3097 11.0901 8.0901C15.3097 3.87053 21.0326 1.5 27 1.5C32.9674 1.5 38.6903 3.87053 42.9099 8.0901C47.1295 12.3097 49.5 18.0326 49.5 24Z"
        stroke="white"
        strokeWidth="3"
      />
    </g>
    <path
      d="M39 25.2031C39 18.5757 33.6274 13.2031 27 13.2031C20.3726 13.2031 15 18.5757 15 25.2031C15 31.8305 20.3726 37.2031 27 37.2031C33.6274 37.2031 39 31.8305 39 25.2031Z"
      stroke="#314663"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.4805 33.7227L35.5205 16.6827"
      stroke="#314663"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <defs>
      <filter
        id="filter0_d_5954_17128"
        x="0"
        y="0"
        width="54"
        height="66"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="3" />
        <feGaussianBlur stdDeviation="1.5" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_5954_17128" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_5954_17128"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
)
