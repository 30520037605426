import { NotSquareIconProps } from '@/lib/core/interfaces/common'

export const QuestionCircleIcon = ({
  color = 'currentColor',
  height = '19',
  width = '20',
}: NotSquareIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="9.91211" cy="9.16211" r="8.63867" stroke={color} />
    <path
      d="M8.8177 10.8777V10.2075C8.8177 10.0288 8.82583 9.87856 8.84207 9.75671C8.86644 9.62673 8.903 9.51301 8.95174 9.41553C9.0086 9.30993 9.07765 9.21245 9.15888 9.12309C9.24824 9.02561 9.35384 8.91189 9.47569 8.78191L10.4139 7.83149C10.617 7.62841 10.7185 7.3644 10.7185 7.03947C10.7185 6.72267 10.6129 6.46678 10.4017 6.27183C10.1987 6.06874 9.93871 5.9672 9.62191 5.9672C9.28073 5.9672 9.00048 6.08499 8.78115 6.32057C8.56182 6.54802 8.43591 6.82827 8.40342 7.16132L6.84375 7.03947C6.89249 6.64956 6.99403 6.30432 7.14837 6.00376C7.30271 5.69507 7.50173 5.43513 7.74543 5.22392C7.99725 5.01272 8.28563 4.85432 8.61056 4.74871C8.93549 4.63499 9.29291 4.57812 9.68283 4.57812C10.0484 4.57812 10.3855 4.63093 10.6942 4.73653C11.011 4.84213 11.2831 4.99647 11.5106 5.19955C11.7461 5.39451 11.9289 5.64227 12.0589 5.94283C12.1889 6.23527 12.2538 6.57239 12.2538 6.95418C12.2538 7.22225 12.2173 7.45782 12.1442 7.6609C12.0711 7.86399 11.9736 8.05082 11.8517 8.22141C11.7299 8.392 11.5877 8.55446 11.4253 8.7088C11.2709 8.86315 11.1044 9.02561 10.9257 9.1962C10.812 9.3018 10.7145 9.39522 10.6333 9.47645C10.552 9.55769 10.483 9.64298 10.4261 9.73234C10.3774 9.81357 10.3408 9.91105 10.3164 10.0248C10.2921 10.1304 10.2799 10.2603 10.2799 10.4147V10.8777H8.8177ZM8.57401 12.5714C8.57401 12.3115 8.66742 12.0881 8.85426 11.9012C9.04922 11.7144 9.28073 11.621 9.5488 11.621C9.80874 11.621 10.0362 11.7104 10.2312 11.8891C10.4261 12.0678 10.5236 12.2871 10.5236 12.547C10.5236 12.807 10.4261 13.0304 10.2312 13.2172C10.0443 13.4041 9.81686 13.4975 9.5488 13.4975C9.41882 13.4975 9.29291 13.4731 9.17107 13.4244C9.05734 13.3756 8.9558 13.3106 8.86644 13.2294C8.77709 13.1482 8.70398 13.0507 8.64711 12.937C8.59837 12.8232 8.57401 12.7014 8.57401 12.5714Z"
      fill={color}
    />
  </svg>
)
