import styled from 'styled-components'

export const SidebarNavigation = styled.nav`
  position: sticky;
  top: 0;
  display: flex;
  flex-direction: column;
  grid-area: sidebar;
  background: ${({ theme: { colors } }) => colors.backgroundOpposite};
  color: ${({ theme: { colors } }) => colors.textOpposite};
  font-family: ${({ theme: { fontFamily } }) => fontFamily.default};
`

export const SidebarList = styled.ul`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  margin: ${({ theme: { layout } }) => layout.spacing(4)} 0 0 0;
  padding: 0;
  list-style: none;
`

export const SidebarLink = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: ${({ theme: { layout } }) => layout.spacing(3)} auto;
  grid-gap: ${({ theme: { layout } }) => layout.spacing(2)};
  justify-items: flex-start;
  min-height: ${({ theme: { layout } }) => layout.spacing(7)};
  padding: ${({ theme: { layout } }) => layout.spacing(0.5)};
  padding-left: 26px;
  color: ${({ theme: { colors } }) => colors.textOpposite};
  text-decoration: none;

  &::after {
    border: none;
  }
`
