import { ComponentPropsWithRef } from 'react'

import { DefaultIconProps } from '@/lib/core/interfaces/common'

export const CheckBoxIcon = ({
  color = 'currentColor',
  size = '16',
  strokeWidth = 1.5,
  ...props
}: DefaultIconProps & ComponentPropsWithRef<'svg'>) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m17.67 9-7.336 7.33L7 12.998"
      stroke={color}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
