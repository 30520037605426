import styled from 'styled-components'
import Link from 'next/link'

export const HelpersContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
  gap: ${({ theme: { layout } }) => layout.spacing(3)};
`

export const HelpLink = styled(Link)`
  display: flex;
  align-items: center;
  color: ${({ theme: { colors } }) => colors.textInput};
  text-decoration: none;
  gap: ${({ theme: { layout } }) => layout.spacing(1)};

  &::after {
    border: none;
  }
`
