import { produce } from 'immer'

import { theme as defaultTheme } from './main'
import type { Theme } from './themes'

export type ModifyThemeFunctionType = (currentTheme: Theme) => Theme | void

/**
 * Allows overriding the default values of a given theme.
 *
 * @param modifyThemeFunction a function that provides the current theme as an argument.
 * You can:
 * - mutate the param directly to override the values (wrapped in immer's produce) - in
 * such case, the function should not return anything.
 * - return the new theme object instead of mutating the current theme.
 *
 * @param theme
 * The theme object that you want to override. Defaults to the main library theme.
 *
 * @returns
 * The new theme object.
 */
export const getTheme = (
  modifyThemeFunction: ModifyThemeFunctionType,
  theme: Theme = defaultTheme,
) => produce(theme, modifyThemeFunction)
