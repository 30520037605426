import { StrictMode, useEffect } from 'react'
import Head from 'next/head'
import { SessionProvider } from 'next-auth/react'
import omit from 'lodash-es/omit'
import isBetween from 'dayjs/plugin/isBetween'
import timezone from 'dayjs/plugin/timezone'
import { AppProps } from 'next/app'
import utc from 'dayjs/plugin/utc'
import minMax from 'dayjs/plugin/minMax'
import dayjs from 'dayjs'
import { Provider } from 'react-redux'
import 'animate.css'
import 'react-toastify/dist/ReactToastify.css'
import { ToastContainer } from 'react-toastify'

import styles from 'src/components/Toaster/Toaster.module.css'
import { FeatureFlagProvider } from '@/feature/feature-flags'
import { ThemeProvider } from '@/components/ThemeProvider'
import { OnboardingProvider } from '@/feature/onboarding/components/OnboardingProvider'

import { wrapper } from '@/lib/core/store/store'
import { initializeApp } from '@/lib/core/store/actions'
import { Layout } from '@/lib/core/components/Layout'
import { Session } from '@/lib/core/components/Session'
import { useTheme } from '@/lib/core/hooks/useTheme'

import { GlobalStyle } from '@/lib/themes/globalStyles'

dayjs.extend(isBetween)
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(minMax)

const MyApp = ({ Component, pageProps }: AppProps) => {
  const theme = useTheme()

  const cleanedPageProps = omit(pageProps, ['messages'])

  return (
    <SessionProvider session={pageProps.session}>
      <ThemeProvider theme={{ ...theme }}>
        <Head>
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1, maximum-scale=1, minimum-scale=1"
          />
          <title>Bridge - GoMetro</title>
        </Head>
        <GlobalStyle />
        <Layout>
          <Session>
            <FeatureFlagProvider>
              <OnboardingProvider>
                <Component {...cleanedPageProps} />
              </OnboardingProvider>
            </FeatureFlagProvider>
          </Session>
        </Layout>
      </ThemeProvider>
    </SessionProvider>
  )
}

const contextClass = {
  success: styles.success,
  error: styles.error,
  default: styles.default,
  warning: styles.warning,
  info: styles.info,
}

const WrappedApp = (rest: AppProps) => {
  const { store, props } = wrapper.useWrappedStore(rest)

  useEffect(() => {
    store.dispatch(initializeApp())
  }, [])

  return (
    <StrictMode>
      <Provider store={store}>
        <MyApp {...props} />
      </Provider>
      <ToastContainer
        position="bottom-center"
        hideProgressBar={true}
        toastClassName={(context) => {
          return [contextClass[context?.type ?? 'default'], styles.container].join(' ')
        }}
        bodyClassName={styles.body}
      />
    </StrictMode>
  )
}

export default WrappedApp
