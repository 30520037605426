import { DefaultIconProps } from '@/lib/core/interfaces/common'

export const InboxIcon = ({
  color = 'currentColor',
  size = '16',
  strokeWidth = '1.5',
}: DefaultIconProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 22 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21 9.39307H15L13 12.3931H9L7 9.39307H1M21 9.39307V15.3931C21 15.9235 20.7893 16.4322 20.4142 16.8073C20.0391 17.1824 19.5304 17.3931 19 17.3931H3C2.46957 17.3931 1.96086 17.1824 1.58579 16.8073C1.21071 16.4322 1 15.9235 1 15.3931V9.39307M21 9.39307L17.55 2.50307C17.3844 2.16985 17.1292 1.88944 16.813 1.69335C16.4967 1.49725 16.1321 1.39326 15.76 1.39307H6.24C5.86792 1.39326 5.50326 1.49725 5.18704 1.69335C4.87083 1.88944 4.61558 2.16985 4.45 2.50307L1 9.39307"
      stroke={color}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
