import { NotSquareIconProps } from '@/lib/core/interfaces/common'

export const MergeIcon = ({
  color = 'currentColor',
  height = '20',
  width = '22',
}: NotSquareIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 22 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.14592 12.9807V6.31932C5.53118 5.96058 6.55843 4.71355 6.55843 3.21659C6.55843 1.44027 5.11792 0 3.34159 0C1.56527 0 0.125 1.44027 0.125 3.21659C0.125 4.71355 1.15224 5.96058 2.53751 6.31932V12.9805C1.15224 13.3392 0.125 14.5863 0.125 16.0832C0.125 17.8598 1.56527 19.3 3.34159 19.3C5.11792 19.3 6.55843 17.8598 6.55843 16.0835C6.55843 14.5865 5.53118 13.3395 4.14592 12.9807ZM1.73342 3.21659C1.73342 2.32831 2.45331 1.60842 3.34159 1.60842C4.22988 1.60842 4.95001 2.32831 4.95001 3.21659C4.95001 4.10488 4.22988 4.82501 3.34159 4.82501C2.45331 4.82501 1.73342 4.10488 1.73342 3.21659ZM3.34159 17.6916C2.45331 17.6916 1.73342 16.9717 1.73342 16.0835C1.73342 15.1952 2.45331 14.475 3.34159 14.475C4.22988 14.475 4.95001 15.1952 4.95001 16.0835C4.95001 16.9717 4.22988 17.6916 3.34159 17.6916Z"
      fill={color}
    />
    <path
      d="M19.0138 12.9807V5.6291C19.0138 3.85374 17.5742 2.41251 15.7979 2.41251H12.9135L14.188 1.13726L13.0508 0L10.4035 2.64821C10.0894 2.96159 10.0894 3.47111 10.4035 3.78546L13.0518 6.43367L14.1888 5.29617L12.9135 4.02092H15.7979C16.6862 4.02092 17.4054 4.74178 17.4054 5.6291V12.9807C16.0211 13.3395 14.9929 14.5865 14.9929 16.0835C14.9929 17.8598 16.4334 19.3 18.2097 19.3C19.986 19.3 21.4263 17.8598 21.4263 16.0835C21.4263 14.5865 20.3983 13.3395 19.0138 12.9807ZM18.2097 17.6916C17.3214 17.6916 16.6013 16.9717 16.6013 16.0835C16.6013 15.1952 17.3214 14.475 18.2097 14.475C19.098 14.475 19.8179 15.1952 19.8179 16.0835C19.8179 16.9717 19.098 17.6916 18.2097 17.6916Z"
      fill={color}
    />
  </svg>
)
