import { ComponentPropsWithRef } from 'react'

import { DefaultIconProps } from '@/lib/core/interfaces/common'

export const TargetIcon = ({
  color = 'currentColor',
  size = '16',
  ...props
}: DefaultIconProps & ComponentPropsWithRef<'svg'>) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 52 52"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M2 25.9993H9.19994"
      stroke="#1945B6"
      strokeWidth="3.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M42.7998 25.9993H49.9997"
      stroke="#1945B6"
      strokeWidth="3.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M25.999 2V9.19977"
      stroke="#1945B6"
      strokeWidth="3.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M25.999 42.7986V49.9984"
      stroke="#1945B6"
      strokeWidth="3.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M25.9981 42.7989C35.2764 42.7989 42.798 35.2775 42.798 25.9994C42.798 16.7213 35.2764 9.19995 25.9981 9.19995C16.7198 9.19995 9.19824 16.7213 9.19824 25.9994C9.19824 35.2775 16.7198 42.7989 25.9981 42.7989Z"
      stroke="#1945B6"
      strokeWidth="3.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
