import { createPaginatedNetworkRequestHook } from '@/utils/paginatedNetworkRequestHookFactory'

import {
  ActiveSharesPayload,
  ActiveSharesResponse,
  CancelActiveSharesPayload,
  CancelActiveSharesResponse,
  ExtendActiveShareId,
  HistorySharesPayload,
  HistorySharesResponse,
  OrganizationChargesPayload,
  OrganizationChargesResponse,
  PostSharesPayload,
  PostSharesResponse,
  Share,
  ShareChargesPayload,
  ShareChargesResponse,
  SharesSharedFromMeResponse,
  SharesSharedToMeResponse,
  SinglePostSharesPayload,
  SinglePostSharesResponse,
  UpdateMapAccessPayload,
  UpdateMapAccessResponse,
} from '@/lib/features/SmartShares/interfaces'

import { request } from '@/lib/utils/request'

const baseUrl = 'v1/smart-shares'

export const makeApi = (instance = request) => ({
  cancelSmartShare: (shareId: CancelActiveSharesPayload) =>
    instance.post<CancelActiveSharesResponse>(`${baseUrl}/${shareId}/cancel/`),
  getOrganizationCharges: ({ organizationId, ...params }: OrganizationChargesPayload) =>
    instance.get<OrganizationChargesResponse>(`${baseUrl}/charges/${organizationId}/`, { params }),
  getShares: (params: HistorySharesPayload) =>
    instance.get<HistorySharesResponse>(`${baseUrl}/`, { params }),
  getSharesActive: (params: ActiveSharesPayload) =>
    instance.get<ActiveSharesResponse>(`${baseUrl}/active/`, { params }),
  getSharesCharges: (params: ShareChargesPayload) =>
    instance.get<ShareChargesResponse>(`${baseUrl}/charges/`, { params }),
  getSharesHistory: (params: HistorySharesPayload) =>
    instance.get<HistorySharesResponse>(`${baseUrl}/history/`, { params }),
  getSharesSharedFromMe: () =>
    instance.get<SharesSharedFromMeResponse>(`${baseUrl}/active/shared-from-me/`),
  getSharesSharedToMe: () =>
    instance.get<SharesSharedToMeResponse>(`${baseUrl}/active/shared-to-me/`),
  shareAssets: (data: PostSharesPayload) => instance.post<PostSharesResponse>(`${baseUrl}/`, data),
  updateShareAssets: (shareId: ExtendActiveShareId, data: SinglePostSharesPayload) =>
    instance.patch<SinglePostSharesResponse>(`${baseUrl}/${shareId}/`, data),
  updateSmartShareMapAccess: ({ id, data }: UpdateMapAccessPayload) =>
    instance.patch<UpdateMapAccessResponse>(`${baseUrl}/${id}/map-access/`, data),
})

export const api = makeApi()

export const useActiveShares = createPaginatedNetworkRequestHook<Share, ActiveSharesPayload>(
  `${baseUrl}/active/`,
)

export const useHistorySmartShares = createPaginatedNetworkRequestHook<Share, ActiveSharesPayload>(
  `${baseUrl}/history/`,
)
