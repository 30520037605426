import { ActionReducerMapBuilder, createReducer } from '@reduxjs/toolkit'

import { RequestStatus } from '@/lib/core/interfaces/common'

import type { MapState } from '@/lib/features/Map/interfaces'

import { getMyTelemetryCredentials } from './actions'

export const initialState: MapState = {
  myTelemetryCredentials: {
    data: null,
    error: null,
    status: RequestStatus.IDLE,
  },
}

export const mapReducer = createReducer(
  initialState,
  (builder: ActionReducerMapBuilder<MapState>) => {
    builder
      .addCase(getMyTelemetryCredentials.pending, (state) => {
        state.myTelemetryCredentials.status = RequestStatus.LOADING
      })
      .addCase(getMyTelemetryCredentials.fulfilled, (state, { payload }) => {
        state.myTelemetryCredentials.status = RequestStatus.SUCCEEDED
        state.myTelemetryCredentials.data = payload
        state.myTelemetryCredentials.error = null
      })
      .addCase(getMyTelemetryCredentials.rejected, (state) => {
        state.myTelemetryCredentials.status = RequestStatus.FAILED
      })
  },
)
