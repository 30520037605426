import { usePaginatedNetworkResource } from '@/lib/core/hooks/usePaginatedNetworkResource'

import {
  AddAssetPTUPayload,
  AddAssetPTUResponse,
  AddAssetVehiclePayload,
  AddAssetVehicleResponse,
  Asset,
  AssetPayload,
  AssetResponse,
  AssetsPayload,
  AssetsResponse,
  BridgedFleetPayload,
  BridgedFleetResponse,
  DeleteAssetPayload,
  DeleteAssetResponse,
  MyVehiclesPayload,
  MyVehiclesResponse,
  PatchMyPtuPayload,
  PatchMyPtuResponse,
  PatchMyVehiclePayload,
  PatchMyVehicleResponse,
  PTUPayload,
  PTUResponse,
  SinglePtuResponse,
  SingleVehicleResponse,
  TrackingSourceResponse,
} from '@/lib/features/Assets/interfaces'

import { request } from '@/lib/utils/request'

const baseUrl = 'v1/assets'

export const makeApi = (instance = request) => ({
  addAssetPTU: (data: AddAssetPTUPayload) =>
    instance.post<AddAssetPTUResponse>(`${baseUrl}/portable-tracking-units/my/`, data),
  addAssetVehicle: (data: AddAssetVehiclePayload) =>
    instance.post<AddAssetVehicleResponse>(`${baseUrl}/vehicles/my/`, data),
  deleteAssetPTU: (id: DeleteAssetPayload) =>
    instance.delete<DeleteAssetResponse>(`${baseUrl}/portable-tracking-units/my/${id}/`),
  deleteAssetVehicle: (id: DeleteAssetPayload) =>
    instance.delete<DeleteAssetResponse>(`${baseUrl}/vehicles/my/${id}/`),
  getAssets: (params: AssetsPayload) =>
    instance.get<AssetsResponse>(`${baseUrl}/assets/`, { params }),
  getBridgedFleet: (params: BridgedFleetPayload) =>
    instance.get<BridgedFleetResponse>(`${baseUrl}/vehicles/`, { params }),
  getMyVehicles: (params: MyVehiclesPayload) =>
    instance.get<MyVehiclesResponse>(`${baseUrl}/vehicles/my/`, { params }),
  getPTU: (params: PTUPayload) =>
    instance.get<PTUResponse>(`${baseUrl}/portable-tracking-units/`, { params }),
  getSingleAsset: (id: AssetPayload) => instance.get<AssetResponse>(`${baseUrl}/assets/${id}/`),
  getSinglePtu: (id: AssetPayload) =>
    instance.get<SinglePtuResponse>(`v1/assets/portable-tracking-units/${id}/`),
  getSingleVehicle: (id: AssetPayload) =>
    instance.get<SingleVehicleResponse>(`v1/assets/vehicles/${id}/`),
  getTrackingSources: () => instance.get<TrackingSourceResponse>(`${baseUrl}/telemetries/`),
  patchAssetPTU: ({ objectId, ...data }: PatchMyPtuPayload) =>
    instance.patch<PatchMyPtuResponse>(`${baseUrl}/portable-tracking-units/my/${objectId}/`, data),
  patchAssetVehicle: ({ objectId, ...data }: PatchMyVehiclePayload) =>
    instance.patch<PatchMyVehicleResponse>(`${baseUrl}/vehicles/my/${objectId}/`, data),
})

export const api = makeApi()

export const useAllAssets = (
  params?: AssetsPayload,
  swrOptions?: Parameters<typeof usePaginatedNetworkResource<Asset>>[2],
) => usePaginatedNetworkResource<Asset>(`${baseUrl}/assets/`, { params }, swrOptions)
export const useMyVehicles = (
  params?: MyVehiclesPayload,
  swrOptions?: Parameters<typeof usePaginatedNetworkResource<Asset>>[2],
) => usePaginatedNetworkResource<Asset>(`${baseUrl}/vehicles/my/`, { params }, swrOptions)
export const useBridgedAssets = (
  params?: BridgedFleetPayload,
  swrOptions?: Parameters<typeof usePaginatedNetworkResource<Asset>>[2],
) => usePaginatedNetworkResource<Asset>(`${baseUrl}/vehicles/`, { params }, swrOptions)
export const usePtuAssets = (
  params?: PTUPayload,
  swrOptions?: Parameters<typeof usePaginatedNetworkResource<Asset>>[2],
) =>
  usePaginatedNetworkResource<Asset>(`${baseUrl}/portable-tracking-units/`, { params }, swrOptions)
