import { createContext, ReactNode, useEffect, useState } from 'react'
import { getSession, signOut, useSession } from 'next-auth/react'
import { useRouter } from 'next/router'
import * as Sentry from '@sentry/nextjs'

import { useAppDispatch } from '@/lib/core/store/store'

import { logout } from '@/lib/features/Auth/store'

import { request } from '@/lib/utils/request'
import { isTokenCloseToExpiry } from '@/lib/utils/jwt'

export const RefreshContext = createContext<boolean>(false)

type SessionProps = {
  children: ReactNode
}

export const Session = ({ children }: SessionProps) => {
  const dispatch = useAppDispatch()
  const router = useRouter()
  const { data: session } = useSession()

  const [isRefreshing, setIsRefreshing] = useState(false)

  const [isLoaded, setIsLoaded] = useState(false)
  useEffect(() => {
      if (session?.error === 'RefreshAccessTokenError') {
        dispatch(logout())
        signOut().catch(Sentry.captureException)
      }

      if (!session?.refreshToken || !session?.accessToken) {
        request.removeTokens()
      }

      if (session?.accessToken) {

        request.setAuthorizationToken(session.accessToken)

        if (isTokenCloseToExpiry(session.accessToken)) {
          setIsRefreshing(true)
          getSession().then((newSession) => {
            setIsRefreshing(false)
            if (newSession?.accessToken) {
              request.setAuthorizationToken(newSession.accessToken)
            }
            return newSession
          }).catch(Sentry.captureException)
        }
      }

      setIsLoaded(true)
  }, [dispatch, session, router])

  return <RefreshContext.Provider value={isRefreshing}>{isLoaded ? children : null}</RefreshContext.Provider>
}
