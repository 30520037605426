import { DefaultIconProps } from '@/lib/core/interfaces/common'

import { getStrokeWidth } from '@/lib/utils/icon'

export const EyeIcon = ({
  color = 'currentColor',
  size = '13',
  strokeWidth = getStrokeWidth(size),
}: DefaultIconProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 13 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g
      clipPath="url(#a)"
      stroke={color}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M1.465 6.5S3.09 2.71 6.882 2.71s5.416 3.792 5.416 3.792-1.625 3.791-5.416 3.791-5.417-3.791-5.417-3.791" />
      <path d="M6.883 8.125a1.625 1.625 0 1 0 0-3.25 1.625 1.625 0 0 0 0 3.25" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill={color} d="M0 0h12.291v13H0z" />
      </clipPath>
    </defs>
  </svg>
)
