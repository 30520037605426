import template from 'lodash-es/template'
import { ComponentType, ReactNode } from 'react'
import identity from 'lodash-es/identity'

const intlImplementation = {
  formatMessage: (
    message: {
      defaultMessage: string
      [k: string]: unknown
    },
    values?:
      | undefined
      | {
      [k: string]: unknown
    },
  ) => template(message.defaultMessage, { interpolate: templateVariableRegex })(values),
  locale: 'en',
} as const

/** @deprecated don't use this */
export const templateVariableRegex = /{([\s\S]+?)}/g
// This is just to help deal with the pain of migrating out of formatjs #pain #BadChoices
/** @deprecated don't use this */
export const useIntl = () => intlImplementation

/** @deprecated don't use this, use the string directly */
export const FormattedMessage = ({
  children,
  values,
  defaultMessage,
  description,
  tagName: Wrapper,
  ...rest
}: {
  children?: ReactNode
  defaultMessage: string
  description?: string
  values?: {
    [k: string]: unknown
  }
  tagName?: ComponentType | keyof JSX.IntrinsicElements
  [k: string]: unknown
}) => {
  const { formatMessage } = useIntl()

  if (Wrapper) {
    return (<Wrapper {...rest}>{formatMessage({ defaultMessage, description }, values)}</Wrapper>)
  } else {
    return <>{formatMessage({ defaultMessage, description }, values)}</>
  }
}

/** @deprecated don't use this, this just returns the original object */
export const defineMessages = identity

/** @deprecated don't use this */
export const IntlProvider = ({ children }: { children: ReactNode; [k: string]: unknown }) => (
  <>{children}</>
)

/** @deprecated don't use this */
export type MessageFormatElement = unknown
