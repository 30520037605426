import { LoaderComponent } from './Loader'
import {
  LoaderCircularCircle,
  LoaderCircularIcon,
  LoaderLinearBackground,
  LoaderLinearLoader,
  LoaderWrapper,
} from './Loader.styles'

export type { LoaderProps } from './Loader'

export const Loader = Object.assign(LoaderComponent, {
  Styled: {
    CircularCircle: LoaderCircularCircle,
    CircularIcon: LoaderCircularIcon,
    LinearBackground: LoaderLinearBackground,
    LinearLoader: LoaderLinearLoader,
    Wrapper: LoaderWrapper,
  },
})
