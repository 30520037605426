import { Action, combineReducers, Reducer } from 'redux'
import reduceReducers from 'reduce-reducers'

import { configReducer } from '@/lib/core/store/reducers/config'
import { hydrateReducer } from '@/lib/core/store/reducers/hydrate'
import { RootState } from '@/lib/core/interfaces/store'

import { assetsReducer } from '@/lib/features/Assets/store'
import { authReducer } from '@/lib/features/Auth/store'
import { contactsReducer } from '@/lib/features/Contacts/store'
import { sharesReducer } from '@/lib/features/SmartShares/store'
import { accountReducer } from '@/lib/features/Account/store'
import { mapReducer } from '@/lib/features/Map/store'

const featuresReducer = combineReducers({
  account: accountReducer,
  assets: assetsReducer,
  auth: authReducer,
  config: configReducer,
  contacts: contactsReducer,
  map: mapReducer,
  shares: sharesReducer,
})

export const rootReducer = reduceReducers<RootState>(hydrateReducer, featuresReducer) as Reducer<
  RootState,
  Action
>
