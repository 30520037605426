import styled, { css } from 'styled-components'

export type AvatarProps = {
  $isEmpty?: boolean
  $size: number
  $withBorder?: boolean
  $padding: number
}

export const CircleWrapper = styled.span<AvatarProps>`
  overflow: hidden;
  display: block;
  flex-shrink: 0;
  width: ${({ $size }) => `${$size}px`};
  height: ${({ $size }) => `${$size}px`};
  border-radius: 50%;
  ${({ $isEmpty }) =>
    $isEmpty &&
    css`
      background: ${({ theme: { colors } }) => colors.disabled};
    `}
  padding: ${({ $padding }) => `${$padding}px`};
  ${({ $withBorder, theme }) =>
    $withBorder &&
    css`
      border: 1px solid ${theme.colors.disabled};
    `}
  > span {
    border-radius: 50%;
  }

  img {
    width: ${({ $size }) => `${$size}px`};
    height: ${({ $size }) => `${$size}px`};
  }
`
