import { AxiosError } from 'axios'
import { createAction, createAsyncThunk } from '@reduxjs/toolkit'

import { AsyncThunkReject } from '@/lib/core/interfaces/common'
import { SelectOption } from '@/lib/core/components/Select'

import { api } from '@/lib/features/Assets/api'
import {
  AddAssetPTUError,
  AddAssetPTUPayload,
  AddAssetPTUResponse,
  AddAssetVehicleError,
  AddAssetVehiclePayload,
  AddAssetVehicleResponse,
  AssetPayload,
  AssetResponse,
  AssetResponseError,
  AssetsPayload,
  AssetsResponse,
  AssetsResponseError,
  BridgedFleetPayload,
  BridgedFleetResponse,
  BridgedFleetResponseError,
  DeleteAssetPayload,
  DeleteAssetResponse,
  DeleteAssetResponseError,
  MyVehiclesPayload,
  MyVehiclesResponse,
  MyVehiclesResponseError,
  PatchMyPtuPayload,
  PatchMyPtuResponse,
  PatchMyPtuResponseError,
  PatchMyVehiclePayload,
  PatchMyVehicleResponse,
  PatchMyVehicleResponseError,
  PTUPayload,
  PTUResponse,
  PTUResponseError,
  SinglePtuResponse,
  SinglePtuResponseError,
  SingleVehicleResponse,
  SingleVehicleResponseError,
  TrackingSourcePayload,
  TrackingSourceResponse,
  TrackingSourceResponseError,
} from '@/lib/features/Assets/interfaces'
import {
  FormSteps,
  FormType,
  VehicleFormState,
} from '@/lib/features/Assets/components/AddAssetModal/interfaces'

import { getActionPrefix } from '@/lib/utils/helpers'

const actionPrefix = getActionPrefix('assets')

export const resetAddFormState = createAction(`${actionPrefix}/assetForm/reset`)

export const setAssetFormType = createAction(
  `${actionPrefix}/assetForm/setFormType`,
  (payload: FormType) => ({ payload }),
)

export const openAssetFormModal = createAction(`${actionPrefix}/assetForm/openModal`)

export const closeAssetFormModal = createAction(`${actionPrefix}/assetForm/closeModal`)

export const setAssetFormSelectedOption = createAction(
  `${actionPrefix}/assetForm/setSeletecOption`,
  (payload: SelectOption) => ({ payload }),
)

export const setAssetFormState = createAction(
  `${actionPrefix}/assetForm/setState`,
  (payload: VehicleFormState) => ({ payload }),
)

export const patchAssetFormState = createAction(
  `${actionPrefix}/assetForm/patchState`,
  (payload: Partial<VehicleFormState>) => ({ payload }),
)

export const setAssetFormErrors = createAction(
  `${actionPrefix}/assetForm/setErrors`,
  (payload: AddAssetVehicleError | null) => ({ payload }),
)

export const setAssetFormSteps = createAction(
  `${actionPrefix}/assetForm/setSteps`,
  (payload: FormSteps) => ({ payload }),
)

export const lockAssetFormType = createAction(
  `${actionPrefix}/assetForm/lockForm`,
  (payload: FormType) => ({ payload }),
)

export const releaseAssetFormType = createAction(
  `${actionPrefix}/assetForm/releaseAllForms`,
  (payload: FormType) => ({ payload }),
)

export const releaseAllAssetForms = createAction(`${actionPrefix}/assetForm/releaseForm`)

export const addAssetVehicle = createAsyncThunk<
  AddAssetVehicleResponse,
  AddAssetVehiclePayload,
  AsyncThunkReject<AddAssetVehicleError>
>(`${actionPrefix}/addAssetVehicle`, async (payload, thunkApi) => {
  try {
    const { data } = await api.addAssetVehicle(payload)

    return data
  } catch (err) {
    const error = err as AxiosError<AddAssetVehicleError>

    if (!error.response) {
      throw error
    }

    return thunkApi.rejectWithValue(error.response.data)
  }
})

export const patchAssetVehicle = createAsyncThunk<
  PatchMyVehicleResponse,
  PatchMyVehiclePayload,
  AsyncThunkReject<PatchMyVehicleResponseError>
>(`${actionPrefix}/patchAssetVehicle`, async (payload, thunkApi) => {
  try {
    const { data } = await api.patchAssetVehicle(payload)

    return data
  } catch (err) {
    const error = err as AxiosError<PatchMyVehicleResponseError>

    if (!error.response) {
      throw error
    }

    return thunkApi.rejectWithValue(error.response.data)
  }
})

export const deleteAssetVehicle = createAsyncThunk<
  DeleteAssetResponse,
  DeleteAssetPayload,
  AsyncThunkReject<DeleteAssetResponseError>
>(`${actionPrefix}/deleteAssetVehicle`, async (payload, thunkApi) => {
  try {
    const { data } = await api.deleteAssetVehicle(payload)

    return data
  } catch (err) {
    const error = err as AxiosError<DeleteAssetResponseError>

    if (!error.response) {
      throw error
    }

    return thunkApi.rejectWithValue(error.response.data)
  }
})

export const addAssetPTU = createAsyncThunk<
  AddAssetPTUResponse,
  AddAssetPTUPayload,
  AsyncThunkReject<AddAssetPTUError>
>(`${actionPrefix}/addAssetPTU`, async (payload, thunkApi) => {
  try {
    const { data } = await api.addAssetPTU(payload)

    return data
  } catch (err) {
    const error = err as AxiosError<AddAssetPTUError>

    if (!error.response) {
      throw error
    }

    return thunkApi.rejectWithValue(error.response.data)
  }
})

export const patchAssetPTU = createAsyncThunk<
  PatchMyPtuResponse,
  PatchMyPtuPayload,
  AsyncThunkReject<PatchMyPtuResponseError>
>(`${actionPrefix}/patchAssetPTU`, async (payload, thunkApi) => {
  try {
    const { data } = await api.patchAssetPTU(payload)

    return data
  } catch (err) {
    const error = err as AxiosError<PatchMyPtuResponseError>

    if (!error.response) {
      throw error
    }

    return thunkApi.rejectWithValue(error.response.data)
  }
})

export const deleteAssetPTU = createAsyncThunk<
  DeleteAssetResponse,
  DeleteAssetPayload,
  AsyncThunkReject<DeleteAssetResponseError>
>(`${actionPrefix}/deleteAssetPTU`, async (payload, thunkApi) => {
  try {
    const { data } = await api.deleteAssetPTU(payload)

    return data
  } catch (err) {
    const error = err as AxiosError<DeleteAssetResponseError>

    if (!error.response) {
      throw error
    }

    return thunkApi.rejectWithValue(error.response.data)
  }
})

export const getAllAssets = createAsyncThunk<
  AssetsResponse,
  AssetsPayload,
  AsyncThunkReject<AssetsResponseError>
>(`${actionPrefix}/getAll`, async (payload = {}, thunkApi) => {
  try {
    const { data } = await api.getAssets(payload)

    return data
  } catch (err) {
    const error = err as AxiosError<AssetsResponseError>

    if (!error.response) {
      throw error
    }

    return thunkApi.rejectWithValue(error.response.data)
  }
})

export const getSingleAsset = createAsyncThunk<
  AssetResponse,
  AssetPayload,
  AsyncThunkReject<AssetResponseError>
>(`${actionPrefix}/getSingle`, async (payload, thunkApi) => {
  try {
    const { data } = await api.getSingleAsset(payload)

    return data
  } catch (err) {
    const error = err as AxiosError<AssetResponseError>

    if (!error.response) {
      throw error
    }

    return thunkApi.rejectWithValue(error.response.data)
  }
})

export const clearSingleAsset = createAction(`${actionPrefix}/clearSingle`)

export const getSingleVehicle = createAsyncThunk<
  SingleVehicleResponse,
  AssetPayload,
  AsyncThunkReject<SingleVehicleResponseError>
>(`${actionPrefix}/getSingleVehicle`, async (payload, thunkApi) => {
  try {
    const { data } = await api.getSingleVehicle(payload)

    return data
  } catch (err) {
    const error = err as AxiosError<SingleVehicleResponseError>

    if (!error.response) {
      throw error
    }

    return thunkApi.rejectWithValue(error.response.data)
  }
})

export const clearSingleVehicle = createAction(`${actionPrefix}/clearSingleVehicle`)

export const getSinglePtu = createAsyncThunk<
  SinglePtuResponse,
  AssetPayload,
  AsyncThunkReject<SinglePtuResponseError>
>(`${actionPrefix}/getSinglePtu`, async (payload, thunkApi) => {
  try {
    const { data } = await api.getSinglePtu(payload)

    return data
  } catch (err) {
    const error = err as AxiosError<SinglePtuResponseError>

    if (!error.response) {
      throw error
    }

    return thunkApi.rejectWithValue(error.response.data)
  }
})

export const clearSinglePtu = createAction(`${actionPrefix}/clearSinglePtu`)

export const getBridgedFleet = createAsyncThunk<
  BridgedFleetResponse,
  BridgedFleetPayload,
  AsyncThunkReject<BridgedFleetResponseError>
>(`${actionPrefix}/getBridgedFleet`, async (payload = {}, thunkApi) => {
  try {
    const { data } = await api.getBridgedFleet(payload)

    return data
  } catch (err) {
    const error = err as AxiosError<BridgedFleetResponseError>

    if (!error.response) {
      throw error
    }

    return thunkApi.rejectWithValue(error.response.data)
  }
})

export const getMyVehicles = createAsyncThunk<
  MyVehiclesResponse,
  MyVehiclesPayload,
  AsyncThunkReject<MyVehiclesResponseError>
>(`${actionPrefix}/getMy`, async (payload = {}, thunkApi) => {
  try {
    const { data } = await api.getMyVehicles(payload)

    return data
  } catch (err) {
    const error = err as AxiosError<MyVehiclesResponseError>

    if (!error.response) {
      throw error
    }

    return thunkApi.rejectWithValue(error.response.data)
  }
})

export const getPTU = createAsyncThunk<PTUResponse, PTUPayload, AsyncThunkReject<PTUResponseError>>(
  `${actionPrefix}/getPTU`,
  async (payload = {}, thunkApi) => {
    try {
      const { data } = await api.getPTU(payload)

      return data
    } catch (err) {
      const error = err as AxiosError<PTUResponseError>

      if (!error.response) {
        throw error
      }

      return thunkApi.rejectWithValue(error.response.data)
    }
  },
)

export const getTrackingSources = createAsyncThunk<
  TrackingSourceResponse,
  TrackingSourcePayload,
  AsyncThunkReject<TrackingSourceResponseError>
>(`${actionPrefix}/getTrackingSource`, async (_, thunkApi) => {
  try {
    const { data } = await api.getTrackingSources()

    return data
  } catch (err) {
    const error = err as AxiosError<TrackingSourceResponseError>

    if (!error.response) {
      throw error
    }

    return thunkApi.rejectWithValue(error.response.data)
  }
})

export const searchAssets = createAsyncThunk<
  AssetsResponse,
  AssetsPayload,
  AsyncThunkReject<AssetsResponseError>
>(`${actionPrefix}/searchAll`, async (payload = {}, thunkApi) => {
  try {
    const { data } = await api.getAssets(payload)

    return data
  } catch (err) {
    const error = err as AxiosError<AssetsResponseError>

    if (!error.response) {
      throw error
    }

    return thunkApi.rejectWithValue(error.response.data)
  }
})
