import isFunction from 'lodash-es/isFunction'
import { DefaultTheme, ThemeProvider as StyledComponentsThemeProvider } from 'styled-components'
import { PropsWithChildren, useMemo } from 'react'
import { flatten } from 'flat'
import { Theme as RadixTheme } from '@radix-ui/themes'
import Head from 'next/head'

import { theme as mainTheme } from '@/lib/themes'

export type ThemeProviderProps = {
  theme: DefaultTheme | ((theme: DefaultTheme) => DefaultTheme)
}

// This way we can use styled-component theme in our css modules
export const ThemeProvider = ({ children, theme }: PropsWithChildren<ThemeProviderProps>) => {
  const themeVariables = useMemo(
    () =>
      Object.entries(
        flatten<DefaultTheme, Record<string, unknown>>(
          isFunction(theme) ? theme(mainTheme) : theme,
          { delimiter: '-' },
        ),
      ).reduce<string>(
        (acc, [key, value]) => (isFunction(value) ? acc : `${acc}\n--theme-${key}: ${value};`),
        '',
      ),
    [theme],
  )

  return (
    <>
      <Head>
        <style
          dangerouslySetInnerHTML={{
            __html: `
          :root {
${themeVariables}
          }
        `,
          }}
        />
      </Head>
      <StyledComponentsThemeProvider theme={theme}>
        <RadixTheme>{children}</RadixTheme>
      </StyledComponentsThemeProvider>
    </>
  )
}

export default ThemeProvider
