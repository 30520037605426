import { createAsyncThunk } from '@reduxjs/toolkit'

import { api } from '@/lib/features/Map/api'

import { getActionPrefix } from '@/lib/utils/helpers'

const actionPrefix = getActionPrefix('map')

export const getMyTelemetryCredentials = createAsyncThunk(
  `${actionPrefix}/getMyTelemetries`,
  async () => {
    const { data } = await api.getMyTelemetry()

    return data
  },
)
