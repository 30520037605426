import { DefaultIconProps } from '@/lib/core/interfaces/common'

export const ArrowOutIcon = ({ color = 'currentColor', size = '16' }: DefaultIconProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.0001 14.5L6.00008 14.5L6.00008 7.16667L3.33342 7.16667L8.00008 2.5L12.6667 7.16667L10.0001 7.16667L10.0001 14.5Z"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
