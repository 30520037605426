import identity from 'lodash-es/identity'
import isArray from 'lodash-es/isArray'
import isEqual from 'lodash-es/isEqual'
import isFunction from 'lodash-es/isFunction'
import { Children, cloneElement, isValidElement, ReactElement, ReactNode, useCallback } from 'react'

import { useFlag } from '@/feature/feature-flags'

export type WhenFlagProps<T> = {
  children?: ReactNode
  is?: ((flag: T) => boolean) | T
  value?: T
  not?: boolean
}
export const WhenFlag = <T,>({ children, is = identity, value, not }: WhenFlagProps<T>) => {
  if (isFunction(is)) {
    if (!is(value!) !== !not) {
      return children
    }
  } else if (!isEqual(is, value) !== !not) {
    return children
  }
}

export interface FlaggedRenderProps<T> {
  flag: Parameters<typeof useFlag<T>>[0]
  children?: ReactNode
  defaultValue?: Parameters<typeof useFlag<T>>[1]
}
export const FeatureFlag = <T,>({
  flag,
  children,
  defaultValue,
}: FlaggedRenderProps<T>): ReactNode => {
  const flagValue = useFlag(flag, defaultValue)

  const mapChild = useCallback(
    (child: ReactNode) => {
      if (isValidElement(child) && child.type === WhenFlag) {
        return cloneElement(child as ReactElement<WhenFlagProps<T>>, { value: flagValue })
      } else if (flagValue) {
        return child
      }
    },
    [flagValue],
  )

  if (isArray(children)) {
    return Children.map(children, mapChild)
  } else {
    return mapChild(children)
  }
}
