export type MapPtuLostContactIconProps = {
  height?: number
  width?: number
}

export const MapPtuLostContactIcon = ({ height, width }: MapPtuLostContactIconProps) => (
  <svg
    width={width ?? '54'}
    height={height ?? '66'}
    viewBox="0 0 54 66"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_d_5954_17129)">
      <mask id="path-1-inside-1_5954_17129" fill="white">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M33.5962 47.0822C43.6437 44.2163 51 34.9672 51 24C51 10.7452 40.2548 0 27 0C13.7452 0 3 10.7452 3 24C3 34.6099 9.88471 43.6118 19.4304 46.7819L26.6 59.2L33.5962 47.0822Z"
        />
      </mask>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.5962 47.0822C43.6437 44.2163 51 34.9672 51 24C51 10.7452 40.2548 0 27 0C13.7452 0 3 10.7452 3 24C3 34.6099 9.88471 43.6118 19.4304 46.7819L26.6 59.2L33.5962 47.0822Z"
        fill="#15A1CE"
      />
      <path
        d="M33.5962 47.0822L32.7734 44.1972L31.6054 44.5304L30.9982 45.5822L33.5962 47.0822ZM19.4304 46.7819L22.0285 45.2819L21.4582 44.2942L20.3759 43.9348L19.4304 46.7819ZM26.6 59.2L24.0019 60.7L26.6 65.2L29.1981 60.7L26.6 59.2ZM48 24C48 33.592 41.5666 41.6891 32.7734 44.1972L34.4191 49.9671C45.7209 46.7435 54 36.3425 54 24H48ZM27 3C38.598 3 48 12.402 48 24H54C54 9.08831 41.9117 -3 27 -3V3ZM6 24C6 12.402 15.402 3 27 3V-3C12.0883 -3 0 9.08831 0 24H6ZM20.3759 43.9348C12.0201 41.1598 6 33.279 6 24H0C0 35.9407 7.74933 46.0637 18.4849 49.629L20.3759 43.9348ZM29.1981 57.7L22.0285 45.2819L16.8323 48.2819L24.0019 60.7L29.1981 57.7ZM30.9982 45.5822L24.0019 57.7L29.1981 60.7L36.1943 48.5822L30.9982 45.5822Z"
        fill="white"
        mask="url(#path-1-inside-1_5954_17129)"
      />
    </g>
    <g clipPath="url(#clip0_5954_17129)">
      <g clipPath="url(#clip1_5954_17129)">
        <path
          d="M24.0164 37.1637C24.0443 37.398 24.2079 37.5938 24.4336 37.6629C24.6592 37.7321 24.9044 37.6615 25.0588 37.4831L30.1384 31.6134L21.5883 16.8037L24.0164 37.1637Z"
          fill="white"
        />
        <path
          d="M30.7273 31.2078L38.3504 29.7435C38.5752 29.7003 38.7547 29.5325 38.8136 29.3126C38.8155 29.3057 38.8172 29.2989 38.8188 29.2919C38.8717 29.0619 38.784 28.8224 38.595 28.681L22.177 16.3981L30.7273 31.2078Z"
          fill="white"
        />
      </g>
    </g>
    <g filter="url(#filter1_d_5954_17129)">
      <mask id="path-5-inside-2_5954_17129" fill="white">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M33.5962 47.0822C43.6437 44.2163 51 34.9672 51 24C51 10.7452 40.2548 0 27 0C13.7452 0 3 10.7452 3 24C3 34.6099 9.88471 43.6118 19.4304 46.7819L26.6 59.2L33.5962 47.0822Z"
        />
      </mask>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.5962 47.0822C43.6437 44.2163 51 34.9672 51 24C51 10.7452 40.2548 0 27 0C13.7452 0 3 10.7452 3 24C3 34.6099 9.88471 43.6118 19.4304 46.7819L26.6 59.2L33.5962 47.0822Z"
        fill="white"
      />
      <path
        d="M33.5962 47.0822L32.7734 44.1972L31.6054 44.5304L30.9982 45.5822L33.5962 47.0822ZM19.4304 46.7819L22.0285 45.2819L21.4582 44.2942L20.3759 43.9348L19.4304 46.7819ZM26.6 59.2L24.0019 60.7L26.6 65.2L29.1981 60.7L26.6 59.2ZM48 24C48 33.592 41.5666 41.6891 32.7734 44.1972L34.4191 49.9671C45.7209 46.7435 54 36.3425 54 24H48ZM27 3C38.598 3 48 12.402 48 24H54C54 9.08831 41.9117 -3 27 -3V3ZM6 24C6 12.402 15.402 3 27 3V-3C12.0883 -3 0 9.08831 0 24H6ZM20.3759 43.9348C12.0201 41.1598 6 33.279 6 24H0C0 35.9407 7.74933 46.0637 18.4849 49.629L20.3759 43.9348ZM29.1981 57.7L22.0285 45.2819L16.8323 48.2819L24.0019 60.7L29.1981 57.7ZM30.9982 45.5822L24.0019 57.7L29.1981 60.7L36.1943 48.5822L30.9982 45.5822Z"
        fill="white"
        mask="url(#path-5-inside-2_5954_17129)"
      />
    </g>
    <g clipPath="url(#clip2_5954_17129)">
      <path
        d="M39.4004 24.4023C39.4004 17.7749 34.0278 12.4023 27.4004 12.4023C20.773 12.4023 15.4004 17.7749 15.4004 24.4023C15.4004 31.0298 20.773 36.4023 27.4004 36.4023C34.0278 36.4023 39.4004 31.0298 39.4004 24.4023Z"
        stroke="#314663"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.8809 32.9219L35.9209 15.8819"
        stroke="#314663"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_5954_17129"
        x="0"
        y="0"
        width="54"
        height="65.1992"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="3" />
        <feGaussianBlur stdDeviation="1.5" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_5954_17129" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_5954_17129"
          result="shape"
        />
      </filter>
      <filter
        id="filter1_d_5954_17129"
        x="0"
        y="0"
        width="54"
        height="65.1992"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="3" />
        <feGaussianBlur stdDeviation="1.5" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_5954_17129" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_5954_17129"
          result="shape"
        />
      </filter>
      <clipPath id="clip0_5954_17129">
        <rect width="28" height="28" fill="white" transform="translate(41 39) rotate(180)" />
      </clipPath>
      <clipPath id="clip1_5954_17129">
        <rect
          width="19.7218"
          height="19.7218"
          fill="white"
          transform="translate(13.5845 21.3906) rotate(-30)"
        />
      </clipPath>
      <clipPath id="clip2_5954_17129">
        <rect
          width="28.8"
          height="28.8"
          fill="white"
          transform="translate(13 38.8008) rotate(-90)"
        />
      </clipPath>
    </defs>
  </svg>
)
