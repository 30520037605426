import { DefaultIconProps } from '@/lib/core/interfaces/common'

export const MapIcon = ({ color = 'currentColor', size = '16' }: DefaultIconProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2 4L6 2L10 4L14 2V12L10 14L6 12L2 14V4Z"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M6 2V12" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
    <path d="M10 4V14" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
  </svg>
)
