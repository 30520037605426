import { User } from '@/lib/core/interfaces/user'

import {
  ChangePasswordPayload,
  ChangePasswordResponse,
  ResetPasswordPayload,
  ResetPasswordResponse,
  VerifyResetPasswordPayload,
  VerifyResetPasswordResponse,
} from '@/lib/features/Auth/interfaces'

import { request } from '@/lib/utils/request'
import { makeServerSideRequest } from '@/lib/utils/serverSideRequest'

const baseUrl = 'v1/auth/users'

export const makeApi = (instance = request) => ({
  changePassword: (data: ChangePasswordPayload) =>
    instance.post<ChangePasswordResponse>(`${baseUrl}/reset_password_confirm/`, data),
  getUser: () => instance.get<User>(`${baseUrl}/me/`),
  resetPassword: (data: ResetPasswordPayload) =>
    instance.post<ResetPasswordResponse>(`${baseUrl}/reset_password/`, data),
  verifyResetPassword: (data: VerifyResetPasswordPayload) =>
    instance.post<VerifyResetPasswordResponse>(`${baseUrl}/reset_password_confirm/validate/`, data),
})

export const api = makeApi()
export const makeServerSideApi = (token?: string) => makeApi(makeServerSideRequest(token))
