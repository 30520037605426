import { ActionReducerMapBuilder, createReducer } from '@reduxjs/toolkit'

import { RequestStatus } from '@/lib/core/interfaces/common'

import { AuthState } from '@/lib/features/Auth/interfaces/state'

import { getUser, logout, setUser } from './actions'

export const initialState: AuthState = {
  hydrateUserStatus: RequestStatus.IDLE,
  user: null,
}

export const authReducer = createReducer(
  initialState,
  (builder: ActionReducerMapBuilder<AuthState>) => {
    builder
      .addCase(getUser.pending, (state) => {
        state.hydrateUserStatus = RequestStatus.LOADING
      })
      .addCase(getUser.fulfilled, (state, { payload }) => {
        state.hydrateUserStatus = RequestStatus.SUCCEEDED
        state.user = payload
      })
      .addCase(getUser.rejected, (state) => {
        state.hydrateUserStatus = RequestStatus.FAILED
      })
      .addCase(logout, (state) => {
        state.user = null
      })
      .addCase(setUser, (state, action) => {
        state.user = action.payload
      })
  },
)
