import { DefaultIconProps } from '@/lib/core/interfaces/common'

export const ChevronRightIcon = ({ color = 'currentColor', size = '16' }: DefaultIconProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M6 12L10 8L6 4" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
  </svg>
)
