import { FormattedMessage } from 'react-intl'

import { BellIcon, QuestionCircleIcon } from '@/lib/core/components/Icon'

import messages from './TopbarMenu.messages'
import { HelpersContainer, HelpLink } from './TopbarMenu.styles'

export const TopbarMenu = () => (
  <HelpersContainer>
    <HelpLink href="https://help.gometroapp.com/" target="_blank">
      <QuestionCircleIcon />
      <FormattedMessage {...messages.help} />
    </HelpLink>
    <BellIcon size="20" />
  </HelpersContainer>
)
