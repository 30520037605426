import { DefaultIconProps } from '@/lib/core/interfaces/common'

export const FileEditIcon = ({ color = 'currentColor', size = '16' }: DefaultIconProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.6665 8.99992V2.66659C2.6665 2.31296 2.80698 1.97382 3.05703 1.72378C3.30708 1.47373 3.64622 1.33325 3.99984 1.33325H9.6665L13.3332 4.99992V13.3333C13.3332 13.6869 13.1927 14.026 12.9426 14.2761C12.6926 14.5261 12.3535 14.6666 11.9998 14.6666H8.33317"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.3335 1.33325V5.33325H13.3335"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.9465 8.40665C7.07651 8.27664 7.23086 8.17352 7.40072 8.10316C7.57058 8.0328 7.75264 7.99658 7.9365 7.99658C8.12036 7.99658 8.30242 8.0328 8.47229 8.10316C8.64215 8.17352 8.7965 8.27664 8.9265 8.40665C9.05651 8.53666 9.15964 8.691 9.23 8.86087C9.30036 9.03073 9.33657 9.21279 9.33657 9.39665C9.33657 9.58051 9.30036 9.76257 9.23 9.93244C9.15964 10.1023 9.05651 10.2566 8.9265 10.3867L5.29984 14L2.6665 14.6667L3.3265 12.0333L6.9465 8.40665Z"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
