import { ReactNode, useCallback, useEffect, useState } from 'react'
import { Userpilot } from 'userpilot'
import { flatten } from 'flat'
import { pickBy } from 'lodash-es'
import hash from 'stable-hash'

import { useGetUser } from '@/feature/auth'
import { useFlags } from '@/feature/feature-flags'
import { captureError } from '@/utils/captureError'

export const OnboardingProvider = ({ children }: { children: ReactNode }) => {
  const { data: userData, isLoading, error } = useGetUser()

  const [renderChildren, setRenderChildren] = useState(false)

  useEffect(() => {
    Userpilot.initialize(process.env.NEXT_PUBLIC_USERPILOT_TOKEN as string)
  }, [])

  const flags: Record<string, unknown> = flatten(
    pickBy(
      useFlags(),
      (value, key) => key.startsWith('feature') || key === 'newMapSystemForGeofences',
    ),
  )

  const loadUser = useCallback(async () => {
    if (userData?.organization) {
      Userpilot.identify(`${process.env.NEXT_PUBLIC_ENV}_${userData.id}`, {
        name: userData.firstName,
        email: userData.email,
        organization: userData.organization.name,
        environment: process.env.NEXT_PUBLIC_ENV,
        ...flags,
      })
    } else {
      Userpilot.anonymous(flags)
    }
  }, [userData, hash(flags)])

  useEffect(() => {
    loadUser()
      .then(() => setRenderChildren(true))
      .catch(captureError())
  }, [loadUser])

  return (!isLoading || error !== undefined) && renderChildren && children
}
