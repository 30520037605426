export const LogoSmall = () => (
  <svg width="135" height="34" viewBox="0 0 135 34" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M79.006 17.3429H76.3462V4.323H79.6384L83.4327 12.2837L87.2085 4.323H90.4821V17.3429H87.8409V8.56376L84.3813 16.0223H82.4655L79.006 8.56376V17.3429ZM97.6065 7.24317C94.5561 7.24317 92.4544 9.28916 92.4544 12.3767C92.4544 15.4085 94.4817 17.4545 97.6809 17.4545C99.4851 17.4545 100.954 16.8035 101.959 15.6875L100.452 14.1623C99.7641 14.8505 98.8713 15.2225 97.9227 15.2225C96.6393 15.2225 95.6721 14.4785 95.3373 13.2137H102.517C102.759 9.56816 101.122 7.24317 97.6065 7.24317ZM95.2815 11.5211C95.5047 10.2006 96.3603 9.34496 97.6251 9.34496C98.9457 9.34496 99.8013 10.2006 99.8385 11.5211H95.2815ZM108.667 15.0551C109.039 15.0551 109.486 14.9249 110.007 14.6831L110.602 16.7849C109.858 17.2127 108.928 17.4731 107.998 17.4731C106.212 17.4731 104.855 16.4501 104.855 14.3855V9.75415H103.46V7.78257H104.855V5.02979H107.7V7.78257H110.416V9.77275H107.7V13.9019C107.7 14.7389 108.054 15.0737 108.667 15.0551ZM115.192 9.14036V7.33617H112.328V17.3429H115.192V12.5255C115.192 10.8329 116.457 9.73556 118.503 9.92155V7.22458C117.015 7.24317 115.88 7.91277 115.192 9.14036ZM119.21 12.3395C119.21 9.27056 121.367 7.24317 124.585 7.24317C127.784 7.24317 129.942 9.27056 129.942 12.3395C129.942 15.3899 127.784 17.4545 124.585 17.4545C121.367 17.4545 119.21 15.3899 119.21 12.3395ZM127.078 12.3767C127.078 10.7399 126.055 9.62396 124.585 9.62396C123.097 9.62396 122.074 10.7399 122.074 12.3767C122.074 14.0135 123.097 15.1295 124.585 15.1295C126.055 15.1295 127.078 14.0135 127.078 12.3767Z"
      fill="#1F9BB0"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M59.004 14.1419C58.167 14.5697 57.237 14.8487 56.4558 14.8487C54.1494 14.8487 52.3452 13.0817 52.3452 10.7753C52.3452 8.48753 54.1308 6.72054 56.4372 6.72054C57.702 6.72054 59.0412 7.27854 60.0083 8.17133L61.6637 6.06955C60.3431 4.91636 58.3716 4.17236 56.4372 4.17236C52.3824 4.17236 49.332 7.01814 49.332 10.7753C49.332 14.5511 52.3266 17.4155 56.2698 17.4155C58.074 17.4155 60.1757 16.7273 61.5521 15.7229V10.2469H59.2943H57.5934L57.1074 12.6767H59.004V14.1419ZM68.5851 7.18554C65.3673 7.18554 63.2097 9.21292 63.2097 12.2819C63.2097 15.3323 65.3673 17.3969 68.5851 17.3969C71.7842 17.3969 73.9418 15.3323 73.9418 12.2819C73.9418 9.21292 71.7842 7.18554 68.5851 7.18554ZM68.5851 9.56632C70.0544 9.56632 71.0774 10.6823 71.0774 12.3191C71.0774 13.9559 70.0544 15.0719 68.5851 15.0719C67.0971 15.0719 66.0741 13.9559 66.0741 12.3191C66.0741 10.6823 67.0971 9.56632 68.5851 9.56632Z"
      fill="#81D043"
    />
    <path d="M134.482 23.9629L49.3773 28.7784L50.3939 24.1043L134.482 23.9629Z" fill="#81D043" />
    <path d="M3.00098 21.5156V29.4585L11.7628 21.6566L3.00098 13.5713V21.5156Z" fill="#81D043" />
    <path
      d="M38.3534 4.00137H27.6479L20.7286 9.32129L13.8067 4H3V4.00548L20.715 20.3417V20.3664L20.7286 20.354L20.7396 20.365V20.3431L29.5875 12.1839V30L38.3534 22.2063V4.09994V4.04845V4.00137Z"
      fill="url(#paint0_linear_202_2)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_202_2"
        x1="3"
        y1="4.01053"
        x2="38.3535"
        y2="4.01053"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#8BD670" />
        <stop offset="0.807292" stopColor="#15A1CE" />
        <stop offset="1" stopColor="#2392A4" />
      </linearGradient>
    </defs>
  </svg>
)
