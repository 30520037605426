var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"kLl0fzFVoDJ3UyBfvYKPj"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from '@sentry/nextjs'
import getConfig from 'next/config'

import environments from '@/environments'

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN
const dev = process.env.NODE_ENV && process.env.NODE_ENV === 'development'
const environment = environments[process.env.NEXT_PUBLIC_ENV!]

const { buildId } = getConfig().publicRuntimeConfig

Sentry.init({
  release: buildId,
  dsn: SENTRY_DSN,
  enabled: !dev,
  environment,
  // integrations: [
  //   Sentry.replayIntegration(),
  // ],
  // Session Replay
  // replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  // replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.

  tracesSampleRate: 1,
})
