import { createSelector } from '@reduxjs/toolkit'
import dayjs from 'dayjs'

import { RootState } from '@/lib/core/interfaces/store'

import { AssetsState, BridgeStatus } from '@/lib/features/Assets/interfaces'

const featureStateSelector = (state: RootState): AssetsState => state.assets

export const getAssetFormSelector = createSelector(featureStateSelector, (state) => state.addForm)

export const isAssetFormOpenSelector = createSelector(getAssetFormSelector, (state) => state.isOpen)

export const getAssetFormOptionSelector = createSelector(
  getAssetFormSelector,
  (state) => state.selectedOption,
)

export const getAssetFormStateSelector = createSelector(
  getAssetFormSelector,
  (state) => state.state,
)

export const getAssetFormStatusSelector = createSelector(
  getAssetFormSelector,
  (state) => state.status,
)

export const getAssetFormErrorsSelector = createSelector(
  getAssetFormSelector,
  (state) => state.errors,
)

export const getLockedAssetFormsSelector = createSelector(
  getAssetFormSelector,
  (state) => state.lockedForms,
)

export const getAllAssetsSelector = createSelector(featureStateSelector, (state) => state.all)

export const getSingleAssetSelector = createSelector(featureStateSelector, (state) => state.single)

export const getSingleVehicleSelector = createSelector(
  featureStateSelector,
  (state) => state.singleVehicle,
)

export const getSinglePtuSelector = createSelector(featureStateSelector, (state) => state.singlePtu)

export const getBridgedFleetSelector = createSelector(
  featureStateSelector,
  (state) => state.bridged,
)

export const getMyVehiclesSelector = createSelector(featureStateSelector, (state) => state.my)

export const getPTUSelector = createSelector(featureStateSelector, (state) => state.ptu)

export const getTrackingSourceSelector = createSelector(
  featureStateSelector,
  (state) => state.trackingSources,
)

export const getTrackingSourceOptionsSelector = createSelector(getTrackingSourceSelector, (state) =>
  state.data.map<{
    isDisabled?: boolean
    label: string
    value: number
  }>(({ id, name }) => ({
    label: name,
    value: id,
  })),
)

export const getTrackingSourceStatusSelector = createSelector(
  getTrackingSourceSelector,
  (state) => state.status,
)

export const getSearchAssetsSelector = createSelector(featureStateSelector, (state) => state.search)

export const getSearchActiveAssetsSelector = createSelector(getSearchAssetsSelector, (state) => {
  const results = state.data.results.filter(({ bridgeDateFrom, bridgeDateTo, bridgeStatus }) => {
    if (bridgeStatus === BridgeStatus.BORROWED) {
      return dayjs().isBetween(bridgeDateFrom, bridgeDateTo, 'day', '[]')
    }

    return true
  })

  return {
    ...state,
    data: {
      ...state.data,
      count: results.length,
      results,
    },
  }
})
