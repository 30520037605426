import { DefaultIconProps } from '@/lib/core/interfaces/common'

export const TrashIcon = ({ color = 'currentColor', size = '16' }: DefaultIconProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M2 4H14" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M12.6668 4V13.3333C12.6668 14 12.0002 14.6667 11.3335 14.6667H4.66683C4.00016 14.6667 3.3335 14 3.3335 13.3333V4"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.3335 3.99992V2.66659C5.3335 1.99992 6.00016 1.33325 6.66683 1.33325H9.3335C10.0002 1.33325 10.6668 1.99992 10.6668 2.66659V3.99992"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M6.6665 7.33325V11.3333" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
    <path d="M9.3335 7.33325V11.3333" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
  </svg>
)
