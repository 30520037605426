import { useState } from 'react'

import {
  NetworkResourcePaginatedResponse,
  PaginatedList,
  ResponseError,
  SortInfo,
} from '@/lib/core/interfaces/common'

import { Config, useNetworkRequest } from '@/lib/utils/request'

export const usePaginatedNetworkResource = <T, E = ResponseError>(
  url: string,
  config: Config,
  swrOptions?: Parameters<typeof useNetworkRequest<PaginatedList<T>, E>>[1],
): NetworkResourcePaginatedResponse<T, E> => {
  const [sortingInfo, setSortingInfo] = useState<SortInfo>({})
  const [page, setPage] = useState(config?.params?.page ?? 1)
  const [pageSize, setPageSize] = useState(config?.params?.pageSize ?? 20)

  const requestInfo = useNetworkRequest<PaginatedList<T>, E>(
    {
      url,
      config: {
        ...config,
        params: {
          page,
          pageSize,
          ordering: sortingInfo,
          ...config?.params,
        },
      },
    },
    swrOptions,
  )

  return {
    ...requestInfo,
    page,
    pageSize,
    sortingInfo,
    setPage,
    setPageSize,
    setSortingInfo,
  }
}
