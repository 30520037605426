import { DefaultIconProps } from '@/lib/core/interfaces/common'

export const HomeIcon = ({
  color = 'currentColor',
  size = '14',
  strokeWidth = '1.2',
}: DefaultIconProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.33594 5.12222L7.33594 1L13.3359 5.12222V11.6C13.3359 11.9124 13.1955 12.2119 12.9454 12.4328C12.6954 12.6537 12.3562 12.7778 12.0026 12.7778H2.66927C2.31565 12.7778 1.97651 12.6537 1.72646 12.4328C1.47641 12.2119 1.33594 11.9124 1.33594 11.6V5.12222Z"
      stroke={color}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.33789 12.7795V6.89062H9.33789V12.7795"
      stroke={color}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
