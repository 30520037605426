import { ComponentPropsWithRef, ElementType } from 'react'
import type { SWRResponse } from 'swr'

export type Nullable<T = undefined> = T | null
export type AllNullable<T> = { [K in keyof T]: T[K] | null }
export type PickNullable<T, Fields extends keyof T> = {
  [K in keyof T]: K extends Fields ? T[K] | null | undefined : T[K]
}

export type DateString = string // 2022-12-31
export type DateISOString = string // 2022-12-31T23:59:59.000Z

export type FloatString = string // 0.00

export enum RequestStatus {
  IDLE = 'idle',
  LOADING = 'loading',
  SUCCEEDED = 'succeeded',
  FAILED = 'failed',
}

export type MergeElementProps<
  T extends ElementType,
  P extends object = Record<string, unknown>,
> = Omit<ComponentPropsWithRef<T>, keyof P> & P

export interface AsyncThunkReject<T> {
  rejectValue: { [K in keyof T]: T[K] } & { _error?: Array<unknown> }
}

export interface FormFieldsResponseError {
  [key: string]: Array<string>
}

export interface FormResponseError {
  errors: Array<string>
}

export type ResponseError = {
  [key: string]: unknown
}

export type DetailResponseError = {
  detail: string
}

export type DefaultIconProps = {
  color?: 'currentColor' | string
  size?: string | number
  strokeWidth?: string | number
}

export type NotSquareIconProps = Omit<DefaultIconProps, 'size'> & {
  height?: string | number
  width?: string | number
}

export enum SortDirection {
  DESC = 'desc',
  ASC = 'asc',
}

export type PaginatedList<T> = {
  count: number
  next: Nullable
  previous: Nullable
  results: Array<T>
}

export type SortInfo = { [key: string]: SortDirection | undefined }
export type ListFilters = {
  dateFrom?: string
  dateTo?: string
  ordering?: SortInfo
  page?: number
  pageSize?: number
  search?: string
}

export type SmartShareListFilters = ListFilters & {
  shareType?: string
}

export type ContactListFilter = ListFilters & {
  shareType?: string
}

export type ApiRequest<T, E = ResponseError> = {
  status: RequestStatus
  data: T
  error: Nullable<E> | undefined
}

export type List<T, E = ResponseError, DataType = PaginatedList<T>> = ApiRequest<DataType, E>
export type Item<T, E = ResponseError> = ApiRequest<T, E>

export type SelectedRows = {
  [key: string]: boolean
}

export enum ErrorType {
  NOTFOUND = 'Not Found',
  HAS_ACTIVE_SHARES = 'This asset has active shares',
}

export type Currency = Intl.NumberFormatOptions['currency']

export type NetworkResourceResponse<T, E = ResponseError> = SWRResponse<T, E>

export type NetworkResourcePaginatedResponse<T, E = ResponseError> = NetworkResourceResponse<
  PaginatedList<T>,
  E
> & {
  page: number
  pageSize: number
  sortingInfo: SortInfo
  setPage: (page: number) => void
  setPageSize: (pageSize: number) => void
  setSortingInfo: (sortingInfo: SortInfo) => void
}
