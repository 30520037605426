import { defineMessages } from 'react-intl'

export default defineMessages({
  assets: {
    defaultMessage: 'Assets',
    description: 'text from the assets nav item',
  },
  contacts: {
    defaultMessage: 'Contacts',
    description: 'text from the contacts nav item',
  },
  dashboard: {
    defaultMessage: 'Dashboard',
    description: 'text from the dashboard nav item',
  },
  mapOverview: {
    defaultMessage: 'Map Overview',
    description: 'text from the map nav item',
  },
  share: {
    defaultMessage: 'Smart Share',
    description: 'text from the shares nav item',
  },
})
