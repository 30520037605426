import { DefaultIconProps } from '@/lib/core/interfaces/common'

export const ChevronFirstIcon = ({
  color = 'currentColor',
  size = '16',
  strokeWidth = '1.5',
}: DefaultIconProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17 18L11 12L17 6"
      stroke={color}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7 6V18"
      stroke={color}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
