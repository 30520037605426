import { createGlobalStyle } from 'styled-components'

import { Theme } from './themes'

export const GlobalStyle = createGlobalStyle<{ theme: Theme }>`
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  :root {
    --headingsColor: ${({ theme: { colors } }) => colors.textHeading};
    --textColor: ${({ theme: { colors } }) => colors.text};
    --linkColor: ${({ theme: { colors } }) => colors.textLink};
    --font-family: ${({ theme: { fontFamily } }) => fontFamily.default};
    font-weight: 400;
    font-size: ${({ theme: { fontSize } }) => fontSize.base};
    font-family: ${({ theme: { fontFamily } }) => fontFamily.default};
    line-height: ${({ theme: { lineHeight } }) => lineHeight.default};
  }

  body {
    margin: 0;
    background: ${({ theme: { colors } }) => colors.backgroundRoot};
    direction: ${({ theme: { direction } }) => direction};
    color: var(--textColor);
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: var(--headingsColor);
    font-family: ${({ theme: { fontFamily } }) => fontFamily.headings};
  }

  h1,
  .h1 {
    font-size: ${({ theme: { fontSize } }) => fontSize.h1};
  }

  h2,
  .h2 {
    font-size: ${({ theme: { fontSize } }) => fontSize.h2};
  }

  h3,
  .h3 {
    font-size: ${({ theme: { fontSize } }) => fontSize.h3};
  }

  h4,
  .h4 {
    font-size: ${({ theme: { fontSize } }) => fontSize.h4};
  }

  h5,
  .h5 {
    font-size: ${({ theme: { fontSize } }) => fontSize.h5};
  }

  h6,
  .h6 {
    font-size: ${({ theme: { fontSize } }) => fontSize.h6};
  }

  a {
    text-decoration: none;
    color: inherit;
  }

  /* width */
  ::-webkit-scrollbar {
    width: 8px;

  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: white;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #DADADA;
    border-radius: 8px;
  }

  ///* Handle on hover */
  //::-webkit-scrollbar-thumb:hover {
  //  background: #555;
  //}
`
