import { DefaultIconProps } from '@/lib/core/interfaces/common'

export const ClockIcon = ({ color = 'currentColor', size = '16' }: DefaultIconProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.960938 1.49219V6.49219H5.96094M1.01094 11.4957C1.23244 13.5015 2.12164 15.3747 3.53572 16.8144C4.9498 18.2541 6.80671 19.1768 8.80823 19.4343C10.8097 19.6917 12.8397 19.2691 14.5721 18.2342C16.3046 17.1992 17.6389 15.6121 18.3609 13.7277C19.0828 11.8432 19.1505 9.77081 18.5531 7.84326C17.9556 5.91572 16.7277 4.2449 15.0665 3.09914C13.4053 1.95337 11.4072 1.39915 9.39316 1.52547C7.37911 1.6518 5.46595 2.45134 3.96094 3.7957L0.960938 6.4957M9.96094 5.49219V10.4922L13.9609 12.4922"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
