import React, { ComponentPropsWithRef } from 'react'

import { DefaultIconProps } from '@/lib/core/interfaces/common'

export const GeoFenceIcon = ({
  color = 'currentColor',
  size = '22',
  ...props
}: DefaultIconProps & ComponentPropsWithRef<'svg'>) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M16.052 19.75 21.104 11l-5.052-8.75H5.95L.896 11l5.051 8.75zm-5.268-4.666a4.3 4.3 0 1 0 0-8.6 4.3 4.3 0 0 0 0 8.6"
      fillRule="evenodd"
      clipRule="evenodd"
      fill="currentColor"
    />
  </svg>
)
