import { AxiosError } from 'axios'
import { createAsyncThunk } from '@reduxjs/toolkit'

import { AsyncThunkReject } from '@/lib/core/interfaces/common'

import { api } from '@/lib/features/Contacts/api'
import {
  ContactsSharedFromMePayload,
  ContactsSharedFromMeResponse,
  ContactsSharedFromMeResponseError,
  ContactsSharedToMePayload,
  ContactsSharedToMeResponse,
  ContactsSharedToMeResponseError,
  CreateMyInvitationError,
  CreateMyInvitationPayload,
  CreateMyInvitationResponse,
  MyContactsPayload,
  MyContactsResponse,
  MyContactsResponseError,
  MyInvitationsPayload,
  MyInvitationsResponse,
  MyInvitationsResponseError,
  ResendMyInvitationError,
  ResendMyInvitationPayload,
  ResendMyInvitationResponse,
  RevokeMyInvitationError,
  RevokeMyInvitationPayload,
  RevokeMyInvitationResponse,
} from '@/lib/features/Contacts/interfaces'

import { getActionPrefix } from '@/lib/utils/helpers'

const actionPrefix = getActionPrefix('assets')

export const getMyInvitations = createAsyncThunk<
  MyInvitationsResponse,
  MyInvitationsPayload,
  AsyncThunkReject<MyInvitationsResponseError>
>(`${actionPrefix}/getMyInvitations`, async (payload = {}, thunkApi) => {
  try {
    const { data } = await api.getMyInvitations(payload)

    return data
  } catch (err) {
    const error = err as AxiosError<MyInvitationsResponseError>

    if (!error.response) {
      throw error
    }

    return thunkApi.rejectWithValue(error.response.data)
  }
})

export const getContactsSharedFromMe = createAsyncThunk<
  ContactsSharedFromMeResponse,
  ContactsSharedFromMePayload,
  AsyncThunkReject<ContactsSharedFromMeResponseError>
>(`${actionPrefix}/getContactsSharedFromMe`, async (_, thunkApi) => {
  try {
    const { data } = await api.getContactsSharedFromMe()

    return data
  } catch (err) {
    const error = err as AxiosError<ContactsSharedFromMeResponseError>

    if (!error.response) {
      throw error
    }

    return thunkApi.rejectWithValue(error.response.data)
  }
})

export const getContactsSharedToMe = createAsyncThunk<
  ContactsSharedToMeResponse,
  ContactsSharedToMePayload,
  AsyncThunkReject<ContactsSharedToMeResponseError>
>(`${actionPrefix}/getContactsSharedToMe`, async (_, thunkApi) => {
  try {
    const { data } = await api.getContactsSharedToMe()

    return data
  } catch (err) {
    const error = err as AxiosError<ContactsSharedToMeResponseError>

    if (!error.response) {
      throw error
    }

    return thunkApi.rejectWithValue(error.response.data)
  }
})

export const getMyContacts = createAsyncThunk<
  MyContactsResponse,
  MyContactsPayload,
  AsyncThunkReject<MyContactsResponseError>
>(`${actionPrefix}/getMyContacts`, async (filters, thunkApi) => {
  try {
    const { data } = await api.getMyContacts(filters)

    return data
  } catch (err) {
    const error = err as AxiosError<MyContactsResponseError>

    if (!error.response) {
      throw error
    }

    return thunkApi.rejectWithValue(error.response.data)
  }
})

export const createMyInvitation = createAsyncThunk<
  CreateMyInvitationResponse,
  CreateMyInvitationPayload,
  AsyncThunkReject<CreateMyInvitationError>
>(`${actionPrefix}/createMyInvitation`, async (payload, thunkApi) => {
  try {
    const { data } = await api.createMyInvitation(payload)

    return data
  } catch (err) {
    const error = err as AxiosError<CreateMyInvitationError>

    if (!error.response) {
      throw error
    }

    return thunkApi.rejectWithValue(error.response.data)
  }
})

export const resendMyInvitation = createAsyncThunk<
  ResendMyInvitationResponse,
  ResendMyInvitationPayload,
  AsyncThunkReject<ResendMyInvitationError>
>(`${actionPrefix}/resendMyInvitation`, async (payload, thunkApi) => {
  try {
    const { data } = await api.resendMyInvitation(payload)

    return data
  } catch (err) {
    const error = err as AxiosError<ResendMyInvitationError>

    if (!error.response) {
      throw error
    }

    return thunkApi.rejectWithValue(error.response.data)
  }
})

export const revokeMyInvitation = createAsyncThunk<
  RevokeMyInvitationResponse,
  RevokeMyInvitationPayload,
  AsyncThunkReject<RevokeMyInvitationError>
>(`${actionPrefix}/revokeMyInvitation`, async (payload, thunkApi) => {
  try {
    const { data } = await api.revokeMyInvitation(payload)

    return data
  } catch (err) {
    const error = err as AxiosError<RevokeMyInvitationError>

    if (!error.response) {
      throw error
    }

    return thunkApi.rejectWithValue(error.response.data)
  }
})

export const searchMyContacts = createAsyncThunk<
  MyContactsResponse,
  MyContactsPayload,
  AsyncThunkReject<MyContactsResponseError>
>(`${actionPrefix}/searchMyContacts`, async (filters, thunkApi) => {
  try {
    const { data } = await api.getMyContacts(filters)

    return data
  } catch (err) {
    const error = err as AxiosError<MyContactsResponseError>

    if (!error.response) {
      throw error
    }

    return thunkApi.rejectWithValue(error.response.data)
  }
})
