import { ComponentPropsWithRef } from 'react'

import { DefaultIconProps } from '@/lib/core/interfaces/common'

export const ReplayIcon = ({
  color = 'currentColor',
  size = '16',
  strokeWidth = 1,
  ...props
}: DefaultIconProps & ComponentPropsWithRef<'svg'>) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M1 7a6 6 0 1 0 6-6 6.5 6.5 0 0 0-4.493 1.827L1 4.333m0 0V1m0 3.333h3.333M7 3.667V7l2.667 1.333"
      stroke={color}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
