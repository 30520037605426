import { ComponentPropsWithRef } from 'react'
import * as React from 'react'

import { DefaultIconProps } from '@/lib/core/interfaces/common'

export const MoreOptionsIcon = ({
  color = 'currentColor',
  size = '20',
  strokeWidth = '1.5',
  ...props
}: DefaultIconProps & ComponentPropsWithRef<'svg'>) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10.001 10.83a.833.833 0 1 0 0-1.666.833.833 0 0 0 0 1.667m0-5.828a.833.833 0 1 0 0-1.667.833.833 0 0 0 0 1.667m0 11.664a.833.833 0 1 0 0-1.667.833.833 0 0 0 0 1.667"
      stroke={color}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
