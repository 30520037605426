import {
  MyColleaguesPayload,
  MyColleaguesResponse,
  OrganizationColleaguesPayload,
  PatchMyAccountPayload,
  PatchMyAccountResponse,
  SinglePatchShareContactPayload,
  SinglePatchShareContactResponse,
} from '@/lib/features/Account/interfaces'

import { request } from '@/lib/utils/request'

export const makeApi = (instance = request) => ({
  getMyColleagues: (params: MyColleaguesPayload) =>
    instance.get<MyColleaguesResponse>('v1/organizations/colleagues/my/', { params }),
  getOrganizationsColleagues: (params: OrganizationColleaguesPayload) =>
    instance.get<MyColleaguesResponse>(
      `v1/organizations/${params.organizationId}/colleagues/contactable/`,
      { params },
    ),
  patchShareContact: ({ ...data }: SinglePatchShareContactPayload) =>
    instance.patch<SinglePatchShareContactResponse>(
      `/v1/organizations/${data.organizationId}/colleagues/contactable/${data.id}/`,
      { share_contact: data.shareContact },
    ),
  patchMyAccount: ({ ...data }: PatchMyAccountPayload) =>
    instance.patch<PatchMyAccountResponse>('/v1/auth/users/me/', data),
})

export const api = makeApi()
