import { ActionReducerMapBuilder, createReducer } from '@reduxjs/toolkit'

import { RequestStatus } from '@/lib/core/interfaces/common'

import { AssetsState } from '@/lib/features/Assets/interfaces/state'
import { FormType } from '@/lib/features/Assets/components/AddAssetModal/interfaces'

import {
  addAssetPTU,
  clearSingleAsset,
  clearSinglePtu,
  clearSingleVehicle,
  closeAssetFormModal,
  getAllAssets,
  getBridgedFleet,
  getMyVehicles,
  getPTU,
  getSingleAsset,
  getSinglePtu,
  getSingleVehicle,
  getTrackingSources,
  lockAssetFormType,
  openAssetFormModal,
  patchAssetFormState,
  patchAssetPTU,
  patchAssetVehicle,
  releaseAllAssetForms,
  releaseAssetFormType,
  resetAddFormState,
  searchAssets,
  setAssetFormErrors,
  setAssetFormSelectedOption,
  setAssetFormState,
  setAssetFormType,
} from './actions'

export const initialState: AssetsState = {
  addForm: {
    errors: null,
    formType: FormType.SELECT_TYPE,
    isOpen: false,
    lockedForms: [],
    selectedOption: null,
    state: {},
    status: RequestStatus.IDLE,
    steps: null,
  },
  all: {
    data: {
      count: 0,
      next: null,
      previous: null,
      results: [],
    },
    error: null,
    status: RequestStatus.IDLE,
  },
  bridged: {
    data: {
      count: 0,
      next: null,
      previous: null,
      results: [],
    },
    error: null,
    status: RequestStatus.IDLE,
  },
  my: {
    data: {
      count: 0,
      next: null,
      previous: null,
      results: [],
    },
    error: null,
    status: RequestStatus.IDLE,
  },
  ptu: {
    data: {
      count: 0,
      next: null,
      previous: null,
      results: [],
    },
    error: null,
    status: RequestStatus.IDLE,
  },
  search: {
    data: {
      count: 0,
      next: null,
      previous: null,
      results: [],
    },
    error: null,
    status: RequestStatus.IDLE,
  },
  single: {
    data: null,
    error: null,
    status: RequestStatus.IDLE,
  },
  singlePtu: {
    data: null,
    error: null,
    status: RequestStatus.IDLE,
  },
  singleVehicle: {
    data: null,
    error: null,
    status: RequestStatus.IDLE,
  },
  trackingSources: {
    data: [],
    error: null,
    status: RequestStatus.IDLE,
  },
}

const stepByFormType = {
  [FormType.PTU]: 0,
  [FormType.SELECT_TYPE]: 0,
  [FormType.VEHICLE_BASIC]: 1,
  [FormType.VEHICLE_DETAIL]: 2,
}

export const assetsReducer = createReducer(
  initialState,
  (builder: ActionReducerMapBuilder<AssetsState>) => {
    builder
      .addCase(resetAddFormState, (state) => {
        state.addForm = { ...initialState.addForm }
      })
      .addCase(setAssetFormType, (state, { payload }) => {
        state.addForm.formType = payload

        if ([FormType.VEHICLE_BASIC, FormType.VEHICLE_DETAIL].includes(payload)) {
          state.addForm.steps = {
            current: stepByFormType[payload],
            total: 2,
          }
        }

        if ([FormType.SELECT_TYPE, FormType.PTU].includes(payload)) {
          state.addForm.steps = null
        }
      })
      .addCase(addAssetPTU.pending, (state, { payload }) => {
        state.addForm.status = RequestStatus.LOADING
      })
      .addCase(addAssetPTU.rejected, (state, { payload }) => {
        state.addForm.status = RequestStatus.FAILED
      })
      .addCase(addAssetPTU.fulfilled, (state, { payload }) => {
        state.addForm.status = RequestStatus.SUCCEEDED
      })
      .addCase(openAssetFormModal, (state) => {
        state.addForm.isOpen = true
      })
      .addCase(closeAssetFormModal, (state) => {
        state.addForm = { ...initialState.addForm }
      })
      .addCase(setAssetFormSelectedOption, (state, { payload }) => {
        state.addForm.selectedOption = payload
      })
      .addCase(setAssetFormState, (state, { payload }) => {
        state.addForm.state = payload
      })
      .addCase(patchAssetFormState, (state, { payload }) => {
        state.addForm.state = {
          ...state.addForm.state,
          ...payload,
        }
      })
      .addCase(setAssetFormErrors, (state, { payload }) => {
        state.addForm.errors = payload
      })
      .addCase(lockAssetFormType, (state, { payload }) => {
        state.addForm.lockedForms = [...state.addForm.lockedForms, payload]
      })
      .addCase(releaseAssetFormType, (state, { payload }) => {
        state.addForm.lockedForms = state.addForm.lockedForms.filter(
          (formType) => formType !== payload,
        )
      })
      .addCase(releaseAllAssetForms, (state) => {
        state.addForm.lockedForms = []
      })
      .addCase(getAllAssets.pending, (state) => {
        state.all.status = RequestStatus.LOADING
      })
      .addCase(getAllAssets.fulfilled, (state, { payload }) => {
        state.all.status = RequestStatus.SUCCEEDED
        state.all.data = payload
        state.all.error = null
      })
      .addCase(getAllAssets.rejected, (state, { payload }) => {
        state.all.status = RequestStatus.FAILED
        state.all.error = payload
      })
      .addCase(getSingleAsset.pending, (state) => {
        state.single.status = RequestStatus.LOADING
      })
      .addCase(getSingleAsset.fulfilled, (state, { payload }) => {
        state.single.status = RequestStatus.SUCCEEDED
        state.single.data = payload
        state.single.error = null
      })
      .addCase(getSingleAsset.rejected, (state, { payload }) => {
        state.single.status = RequestStatus.FAILED
        state.single.error = payload
      })
      .addCase(clearSingleAsset, (state) => {
        state.single = initialState.single
      })
      .addCase(getSingleVehicle.pending, (state) => {
        state.singleVehicle.status = RequestStatus.LOADING
      })
      .addCase(getSingleVehicle.fulfilled, (state, { payload }) => {
        state.singleVehicle.status = RequestStatus.SUCCEEDED
        state.singleVehicle.data = payload
        state.singleVehicle.error = null
      })
      .addCase(getSingleVehicle.rejected, (state, { payload }) => {
        state.singleVehicle.status = RequestStatus.FAILED
        state.singleVehicle.error = payload
      })
      .addCase(patchAssetVehicle.fulfilled, (state, { payload }) => {
        state.singleVehicle.status = RequestStatus.SUCCEEDED
        state.singleVehicle.error = null

        if (state.singleVehicle.data) {
          state.singleVehicle.data = {
            ...state.singleVehicle.data,
            ...payload,
          }
        }
      })
      .addCase(patchAssetVehicle.rejected, (state, { payload }) => {
        state.singleVehicle.status = RequestStatus.FAILED
        state.singleVehicle.error = payload
      })
      .addCase(clearSingleVehicle, (state) => {
        state.singleVehicle = initialState.singleVehicle
      })
      .addCase(getSinglePtu.pending, (state) => {
        state.singlePtu.status = RequestStatus.LOADING
      })
      .addCase(getSinglePtu.fulfilled, (state, { payload }) => {
        state.singlePtu.status = RequestStatus.SUCCEEDED
        state.singlePtu.data = payload
        state.singlePtu.error = null
      })
      .addCase(getSinglePtu.rejected, (state, { payload }) => {
        state.singlePtu.status = RequestStatus.FAILED
        state.singlePtu.error = payload
      })
      .addCase(patchAssetPTU.fulfilled, (state, { payload }) => {
        const { id, ...newValues } = payload

        state.singlePtu.status = RequestStatus.SUCCEEDED
        state.singlePtu.error = null

        if (state.singlePtu.data) {
          state.singlePtu.data = {
            ...state.singlePtu.data,
            ...newValues,
            objectId: id,
          }
        }
      })
      .addCase(patchAssetPTU.rejected, (state, { payload }) => {
        state.singlePtu.status = RequestStatus.FAILED
        state.singlePtu.error = payload
      })
      .addCase(clearSinglePtu, (state) => {
        state.singlePtu = initialState.singlePtu
      })
      .addCase(getBridgedFleet.pending, (state) => {
        state.bridged.status = RequestStatus.LOADING
      })
      .addCase(getBridgedFleet.fulfilled, (state, { payload }) => {
        state.bridged.status = RequestStatus.SUCCEEDED
        state.bridged.data = payload
        state.bridged.error = null
      })
      .addCase(getBridgedFleet.rejected, (state, { payload }) => {
        state.bridged.status = RequestStatus.FAILED
        state.bridged.error = payload
      })
      .addCase(getMyVehicles.pending, (state) => {
        state.my.status = RequestStatus.LOADING
      })
      .addCase(getMyVehicles.fulfilled, (state, { payload }) => {
        state.my.status = RequestStatus.SUCCEEDED
        state.my.data = payload
        state.my.error = null
      })
      .addCase(getMyVehicles.rejected, (state, { payload }) => {
        state.my.status = RequestStatus.FAILED
        state.my.error = payload
      })
      .addCase(getPTU.pending, (state) => {
        state.ptu.status = RequestStatus.LOADING
      })
      .addCase(getPTU.fulfilled, (state, { payload }) => {
        state.ptu.status = RequestStatus.SUCCEEDED
        state.ptu.data = payload
        state.ptu.error = null
      })
      .addCase(getPTU.rejected, (state, { payload }) => {
        state.ptu.status = RequestStatus.FAILED
        state.ptu.error = payload
      })
      .addCase(getTrackingSources.pending, (state) => {
        state.trackingSources.status = RequestStatus.LOADING
      })
      .addCase(getTrackingSources.fulfilled, (state, { payload }) => {
        state.trackingSources.status = RequestStatus.SUCCEEDED
        state.trackingSources.data = payload
        state.trackingSources.error = null
      })
      .addCase(getTrackingSources.rejected, (state, { payload }) => {
        state.trackingSources.status = RequestStatus.FAILED
        state.trackingSources.error = payload
      })
      .addCase(searchAssets.pending, (state) => {
        state.search.status = RequestStatus.LOADING
      })
      .addCase(searchAssets.fulfilled, (state, { payload }) => {
        state.search.status = RequestStatus.SUCCEEDED
        state.search.data = payload
        state.search.error = null
      })
      .addCase(searchAssets.rejected, (state, { payload }) => {
        state.search.status = RequestStatus.FAILED
        state.search.error = payload
      })
  },
)
