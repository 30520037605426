import { ComponentPropsWithRef } from 'react'

import { DefaultIconProps } from '@/lib/core/interfaces/common'

export const CheckIcon = ({
  color = 'currentColor',
  size = '16',
  strokeWidth = 1,
  ...props
}: DefaultIconProps & ComponentPropsWithRef<'svg'>) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M13.3332 4L5.99984 11.3333L2.6665 8"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={strokeWidth}
    />
  </svg>
)
