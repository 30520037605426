import { createSelector } from '@reduxjs/toolkit'

import { RootState } from '@/lib/core/interfaces/store'

import { SharesState } from '@/lib/features/SmartShares/interfaces/state'

const featureStateSelector = (state: RootState): SharesState => state.shares

export const getActiveSharesSelector = createSelector(featureStateSelector, (state) => state.active)
export const getHistorySharesSelector = createSelector(
  featureStateSelector,
  (state) => state.history,
)
export const getSharesSharedToMeSelector = createSelector(
  featureStateSelector,
  (state) => state.sharedToMe,
)
export const getSharesSharedFromMeSelector = createSelector(
  featureStateSelector,
  (state) => state.sharedFromMe,
)
export const getOldestShareDateSelector = createSelector(
  featureStateSelector,
  (state) => state.oldestShareDate.data,
)
