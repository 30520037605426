import { CSSProperties } from 'react'

export type MapPtuIconProps = {
  rotate: number
  color: string
  height?: number
  width?: number
}

export const MapPtuIcon = ({ rotate, color, height, width }: MapPtuIconProps) => (
  <svg
    width={width ?? '54'}
    height={height ?? '66'}
    viewBox="0 0 54 66"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    color={color}
    style={
      {
        '--rotation': `${rotate}deg`,
      } as CSSProperties
    }
  >
    <g filter="url(#filter0_d_5954_17095)">
      <mask id="path-1-inside-1_5954_17095" fill="white">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M33.5962 47.0822C43.6437 44.2163 51 34.9672 51 24C51 10.7452 40.2548 0 27 0C13.7452 0 3 10.7452 3 24C3 34.6099 9.88471 43.6118 19.4304 46.7819L26.6 59.2L33.5962 47.0822Z"
        />
      </mask>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.5962 47.0822C43.6437 44.2163 51 34.9672 51 24C51 10.7452 40.2548 0 27 0C13.7452 0 3 10.7452 3 24C3 34.6099 9.88471 43.6118 19.4304 46.7819L26.6 59.2L33.5962 47.0822Z"
        fill="currentColor"
      />
      <path
        d="M33.5962 47.0822L32.7734 44.1972L31.6054 44.5304L30.9982 45.5822L33.5962 47.0822ZM19.4304 46.7819L22.0285 45.2819L21.4582 44.2942L20.3759 43.9348L19.4304 46.7819ZM26.6 59.2L24.0019 60.7L26.6 65.2L29.1981 60.7L26.6 59.2ZM48 24C48 33.592 41.5666 41.6891 32.7734 44.1972L34.4191 49.9671C45.7209 46.7435 54 36.3425 54 24H48ZM27 3C38.598 3 48 12.402 48 24H54C54 9.08831 41.9117 -3 27 -3V3ZM6 24C6 12.402 15.402 3 27 3V-3C12.0883 -3 0 9.08831 0 24H6ZM20.3759 43.9348C12.0201 41.1598 6 33.279 6 24H0C0 35.9407 7.74933 46.0637 18.4849 49.629L20.3759 43.9348ZM29.1981 57.7L22.0285 45.2819L16.8323 48.2819L24.0019 60.7L29.1981 57.7ZM30.9982 45.5822L24.0019 57.7L29.1981 60.7L36.1943 48.5822L30.9982 45.5822Z"
        fill="white"
        mask="url(#path-1-inside-1_5954_17095)"
      />
    </g>
    <g
      style={{
        transform: 'rotate(var(--rotation, 0deg))',
        transformOrigin: 'center 24px',
        transition: 'transform 0.5s ease',
      }}
      clipPath="url(#clip0_5954_17095)"
    >
      <path
        d="M17.9464 33.2056C17.8534 33.4225 17.8972 33.6738 18.058 33.8465C18.2189 34.0193 18.4665 34.0808 18.6894 34.0034L26.0233 31.4599L26.0235 14.3593L17.9464 33.2056Z"
        fill="white"
      />
      <path
        d="M35.8974 33.2056C35.9903 33.4225 35.9465 33.6738 35.7857 33.8465C35.6249 34.0193 35.3772 34.0808 35.1543 34.0034L27.8204 31.4599L27.8202 14.3593L35.8974 33.2056Z"
        fill="white"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_5954_17095"
        x="0"
        y="0"
        width="54"
        height="65.1992"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="3" />
        <feGaussianBlur stdDeviation="1.5" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_5954_17095" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_5954_17095"
          result="shape"
        />
      </filter>
      <clipPath id="clip0_5954_17095">
        <rect width="28" height="28" fill="white" transform="translate(41 38) rotate(180)" />
      </clipPath>
      <clipPath id="clip1_5954_17095">
        <rect
          width="22.1565"
          height="22.1565"
          fill="white"
          transform="translate(22.9432 8.86719) rotate(30)"
        />
      </clipPath>
    </defs>
  </svg>
)
