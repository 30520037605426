import { defineMessages } from 'react-intl'

export default defineMessages({
  bridgeID: {
    defaultMessage: 'Bridge ID:',
    description: 'Bridge ID',
  },
  imageAlt: {
    defaultMessage: 'Organization avatar',
    description: 'sidebar, account section: text for the organization avatar image alt attribute',
  },
})
