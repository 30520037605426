import { DefaultTheme } from 'styled-components'

import { ThemeDirection } from '@/lib/core/constants/constants'

import { shadeHexColor } from '@/lib/utils/colors'

export const LAYOUT_GAP = 8

const palette = {
  // tool to generate color name based on the value:
  // https://chir.ag/projects/name-that-color/#6195ED
  alabaster: '#F8F8F8', // login page footer
  alto: '#D6D6D6',
  aquaHaze: '#f4f7f8', // scrollbar track color
  athensGray: '#EFF1F5', // disbaled button
  azure: '#15A1CEB3', // primary button hover bg (curiousBlue with opacity)
  black: '#000000', // headings
  blueChill: '#0D8983', // share section color
  catskillWhite: '#EFF5F7', // odd table rows, calendar sidebar
  concrete: '#F3F3F3',
  crimson: '#e21313',
  curiousBlue: '#15A1CE', // links, text-buttons
  dustyGray: '#989898', // share modal (form labels / form table heads)
  easternBlue: '#2392A4', // contacts section color
  ebonyClay: '#1F2937',
  fireBush: '#E18B3B', // down icon on table list
  iceberg: '#D5F0EF', // share section form active element
  mantis: '#83BE61', // toggle component, up icon on table list
  mercury: '#E5E5E5', // body background
  mexicanRed: '#9F2828', // remove modal heading
  mineShaft: '#393939', // darker texts
  oldLace: '#FDF9E5', // warning notification box bg
  osloGray: '#91979D', // disabled buttons text color
  polar: '#E6F4F9', // info notification box bg
  poloBlue: '#95B1D7', // highlighted text
  portofinoLight: '#fdf9e5',
  regentStBlue: '#9DD0E2', // border color
  rhino: '#314663', // assets section color
  ripeLemon: '#F6D210', // warning icon color
  silver: '#CCCCCC', // readed msgs on notifications sidebar
  tuna: '#3F3F46', // default text, map tooltip background, buttons bg on modals
  tundora: '#404040', // error notification box text
  viking: '#5BBDDD', // primary button hover bg
  violetRed: '#F12A84', // badges (tabs, table rows, notifications sidebar)
  white: '#ffffff', // content boxes
  wispPink: '#FDEAF2', // error notification box bg
}

const colors: DefaultTheme['colors'] = {
  accent: palette.violetRed, // badges
  active: palette.curiousBlue, // active form elements
  avatarBorder: palette.silver,
  backgroundActive: palette.polar, // active form elements bg
  backgroundContent: palette.white,
  backgroundError: palette.wispPink,
  backgroundHighlight: palette.athensGray,
  backgroundInactive: palette.tundora, // active form elements
  backgroundInfo: palette.polar,
  backgroundLow: palette.alabaster, // (low contrast) login page footer
  backgroundOdd: palette.catskillWhite, // odd table row, calendar sidebar
  backgroundOpposite: palette.ebonyClay, // sidebar, tooltip
  backgroundRoot: palette.mercury, // body
  backgroundTab: palette.rhino, // inactive tab bg
  backgroundWarning: palette.oldLace,
  border: palette.regentStBlue,
  borderGray: palette.alto,
  disabled: palette.silver,
  error: palette.violetRed,
  featureAssets: palette.rhino,
  featureContacts: palette.easternBlue,
  featureShares: palette.blueChill,
  focus: palette.iceberg,
  icon: palette.tundora,
  primary: palette.easternBlue, // info modal icon
  scrollbarThumb: palette.easternBlue,
  scrollbarTrack: palette.aquaHaze,
  secondary: palette.tundora,
  success: palette.mantis,
  tertiary: palette.easternBlue,
  text: palette.tundora,
  textGray: palette.dustyGray,
  textGrayDark: palette.mineShaft,
  textGrayLight: palette.alto,
  textHeading: palette.tundora,
  textHighlighted: palette.poloBlue,
  textInput: palette.rhino,
  textLink: palette.curiousBlue,
  textLow: palette.tuna,
  textOpposite: palette.white,
  warning: palette.ripeLemon,
}

const border: DefaultTheme['border'] = {
  color: colors.border,
  radius: '10px',
  style: 'solid',
  width: '1px',
}

export const theme: DefaultTheme = {
  border,
  borderBase: `${border.width} ${border.style} ${border.color}`,
  breadcrumbs: {
    color: palette.osloGray,
  },
  buttons: {
    ghost: {
      background: 'transparent',
      borderColor: palette.tundora,
      color: palette.tundora,
      disabled: {
        background: 'transparent',
        borderColor: colors.disabled,
        color: colors.disabled,
      },
      hover: {
        background: 'transparent',
        borderColor: palette.curiousBlue,
        color: palette.curiousBlue,
      },
    },
    primary: {
      background: palette.curiousBlue,
      borderColor: palette.curiousBlue,
      color: palette.white,
      disabled: {
        background: palette.athensGray,
        borderColor: palette.athensGray,
        color: palette.osloGray,
      },
      hover: {
        background: palette.azure,
        borderColor: 'transparent',
        color: palette.white,
      },
    },
    secondary: {
      background: palette.tundora,
      borderColor: palette.tundora,
      color: palette.white,
      disabled: {
        background: palette.athensGray,
        borderColor: palette.athensGray,
        color: palette.osloGray,
      },
      hover: {
        background: shadeHexColor(palette.tundora, 0.065),
        borderColor: shadeHexColor(palette.tundora, 0.065),
        color: palette.white,
      },
    },
    tertiary: {
      background: palette.easternBlue,
      borderColor: palette.easternBlue,
      color: palette.white,
      disabled: {
        background: palette.athensGray,
        borderColor: palette.athensGray,
        color: palette.osloGray,
      },
      hover: {
        background: shadeHexColor(palette.easternBlue, 0.3),
        borderColor: shadeHexColor(palette.easternBlue, 0.3),
        color: palette.white,
      },
    },
    text: {
      background: 'transparent',
      borderColor: 'transparent',
      color: palette.curiousBlue,
      disabled: {
        background: 'none',
        borderColor: 'transparent',
        color: palette.osloGray,
      },
      hover: {
        background: 'none',
        color: palette.azure,
      },
    },
  },
  colors,
  direction: ThemeDirection.LTR,
  focus: {
    background: colors.focus,
    border: `${border.width} ${border.style} ${colors.focus}`,
    color: colors.primary,
    textDecoration: 'underline',
  },
  fontFamily: {
    default: '"Inter", Helvetica, Arial, sans-serif',
    headings: '"Inter", Helvetica, Arial, sans-serif',
  },
  fontSize: {
    base: '16px',
    default: '1rem',
    h1: '2.488rem',
    h2: '2.074rem',
    h3: '1.728rem',
    h4: '1.44rem',
    h5: '1.2rem',
    h6: '1rem',
    large: '1.2rem',
    small: '0.833rem',
  },
  layout: {
    borderRadius: '20px',
    spacingRules: {
      gap: LAYOUT_GAP,
      unit: '1px',
    },
    spacing: (n = 1, u = 'px') => `${n * LAYOUT_GAP}${u}`,
  },
  lineHeight: {
    default: '1.6',
    h1: '56px',
    h2: '48px',
    h3: '40px',
    h4: '32px',
    h5: '32px',
    h6: '24px',
  },
  link: {
    color: palette.curiousBlue,
    hover: {
      color: palette.blueChill,
    },
  },
  modals: {
    overlayColor: 'rgba(0, 0, 0, 0.7)',
  },
  outline: {
    border: `2px ${border.style} ${colors.focus}`,
    offset: '1px',
  },
  tags: {
    primary: {
      backgroundColor: 'transparent',
      borderColor: palette.curiousBlue,
      color: palette.curiousBlue,
    },
    secondary: {
      backgroundColor: 'transparent',
      borderColor: palette.tundora,
      color: palette.tundora,
    },
  },
}
