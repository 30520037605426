import styled, { css } from 'styled-components'
import Link from 'next/link'

export const Wrapper = styled.div`
  border-top: 1px solid rgba(255, 255, 255, 0.5);
`

export const CompanyName = styled.p`
  margin: ${({ theme: { layout } }) => `${layout.spacing(3)} 0 0`};
  padding: ${({ theme: { layout } }) => `0 ${layout.spacing(3)}`};

  a {
    color: ${({ theme: { colors } }) => colors.textOpposite};
    text-decoration: underline;
  }
`

export const BridgeId = styled.p`
  margin: ${({ theme: { layout } }) => `0 0 ${layout.spacing(2)}`};
  padding: 0 ${({ theme: { layout } }) => layout.spacing(3)};
`

export const User = styled.p`
  margin: 0;
`

export type UserLinkProps = {
  $active?: boolean
  $isOnlyAvatar?: boolean
}

export const UserLink = styled(Link)<UserLinkProps>`
  display: flex;
  align-items: center;
  gap: ${({ theme: { layout } }) => layout.spacing(2)};
  justify-items: ${({ $isOnlyAvatar }) => ($isOnlyAvatar ? 'center' : 'flex-start')};
  min-height: ${({ theme: { layout } }) => layout.spacing(9)};
  padding: ${({
    $isOnlyAvatar,
    theme: {
      layout: { spacing },
    },
  }) =>
    $isOnlyAvatar
      ? `${spacing(2)} ${spacing(1)}`
      : `${spacing(1)} ${spacing(0.5)} ${spacing(1)} ${spacing(3)}`};
  background: ${({ theme: { colors }, $active }) => ($active ? colors.primary : 'transparent')};
  color: ${({ theme: { colors } }) => colors.textOpposite};
  text-decoration: none;
  ${({ $isOnlyAvatar }) =>
    $isOnlyAvatar &&
    css`
      justify-content: center;
    `}

  &::after {
    border: none;
  }
`

export type InitialAvatarProps = {
  $background: string
  $color: string
}

export const InitialAvatar = styled.span<InitialAvatarProps>`
  overflow: hidden;
  display: flex;
  flex: 0 0 50px;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: ${({ $background }) => $background};
  color: ${({ $color }) => $color};
  font-weight: 700;
  font-size: ${({ theme: { fontSize } }) => fontSize.large};
`

export const UserName = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme: { colors } }) => colors.textOpposite};
  text-decoration: none;
  cursor: pointer;
`
