import { AxiosError } from 'axios'
import { createAsyncThunk } from '@reduxjs/toolkit'

import { AsyncThunkReject } from '@/lib/core/interfaces/common'

import { api } from '@/lib/features/Account/api'
import {
  MyColleaguesPayload,
  MyColleaguesResponse,
  MyColleaguesResponseError,
  OrganizationColleaguesPayload,
  PatchMyAccountPayload,
  PatchMyAccountResponse,
  PatchMyAccountResponseError,
  SinglePatchShareContactPayload,
  SinglePatchShareContactResponse,
} from '@/lib/features/Account/interfaces'

import { getActionPrefix } from '@/lib/utils/helpers'

const actionPrefix = getActionPrefix('account')

export const getOrganizationsColleagues = createAsyncThunk<
  MyColleaguesResponse,
  OrganizationColleaguesPayload,
  AsyncThunkReject<MyColleaguesResponseError>
>(
  `${actionPrefix}/getOrganizationsColleagues`,
  async (payload = { organizationId: null }, thunkApi) => {
    try {
      const { data } = await api.getOrganizationsColleagues(payload)

      return data
    } catch (err) {
      const error = err as AxiosError<MyColleaguesResponseError>

      if (!error.response) {
        throw error
      }

      return thunkApi.rejectWithValue(error.response.data)
    }
  },
)

export const getMyColleagues = createAsyncThunk<
  MyColleaguesResponse,
  MyColleaguesPayload,
  AsyncThunkReject<MyColleaguesResponseError>
>(`${actionPrefix}/getMyColleagues`, async (payload = {}, thunkApi) => {
  try {
    const { data } = await api.getMyColleagues(payload)

    return data
  } catch (err) {
    const error = err as AxiosError<MyColleaguesResponseError>

    if (!error.response) {
      throw error
    }

    return thunkApi.rejectWithValue(error.response.data)
  }
})

export const patchShareContact = createAsyncThunk<
  SinglePatchShareContactResponse,
  SinglePatchShareContactPayload,
  AsyncThunkReject<MyColleaguesResponseError>
>(`${actionPrefix}/patchShareContact`, async (payload, thunkApi) => {
  try {
    const { data } = await api.patchShareContact(payload)
    return data
  } catch (err) {
    const error = err as AxiosError<MyColleaguesResponseError>

    if (!error.response) {
      throw error
    }

    return thunkApi.rejectWithValue(error.response.data)
  }
})

export const patchMyAccount = createAsyncThunk<
  PatchMyAccountResponse,
  PatchMyAccountPayload,
  AsyncThunkReject<PatchMyAccountResponseError>
>(`${actionPrefix}/patchAssetPTU`, async (payload, thunkApi) => {
  try {
    const { data } = await api.patchMyAccount(payload)

    return data
  } catch (err) {
    const error = err as AxiosError<PatchMyAccountResponseError>

    if (!error.response) {
      throw error
    }

    return thunkApi.rejectWithValue(error.response.data)
  }
})
