import Image, { ImageProps } from 'next/image'

import { CircleWrapper } from '@/lib/core/components/CircleAvatar/CircleAvatar.styles'

export type CircleAvatarProps = Omit<ImageProps, 'src'|'alt'> & {
  padding?: number
  size?: number
  src?: string | null
  withBorder?: boolean
  alt?: string
}

export const CircleAvatar = ({
  padding = 0,
  size = 50,
  withBorder = false,
  src,
  alt = 'avatar',
  ...props
}: CircleAvatarProps) => (
  <CircleWrapper
    $size={size}
    $isEmpty={!src?.startsWith('http')}
    $withBorder={withBorder}
    $padding={padding}
  >
    {src?.startsWith('http') && (
      <Image alt={alt} {...props} src={src} width={100} height={100}  />
    )}
  </CircleWrapper>
)
