import { ComponentPropsWithRef, CSSProperties } from 'react'

export type MapSideBarVehicleIconProps = {
  rotate?: number
  color?: string
} & ComponentPropsWithRef<'svg'>

export const MapSideBarVehicleIcon = ({
  rotate = 0,
  color = 'fillColor',
  style,
  ...props
}: MapSideBarVehicleIconProps) => (
  <svg
    width={24}
    height={25}
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    color={color}
    style={
      {
        '--rotation': `${rotate}deg`,
        transition: 'color 0.3s ease-out',
        ...style,
      } as CSSProperties
    }
    {...props}
  >
    <circle cx={12} cy={12.273} r={12} fill="currentColor" />
    <path
      d="M6.859 17.44a.353.353 0 0 0 .44.472l4.345-1.507V6.274zm5.5-1.035 4.344 1.507a.353.353 0 0 0 .44-.472L12.36 6.274z"
      style={{
        transform: 'rotate(var(--rotation, 0deg))',
        transformOrigin: 'center',
        transition: 'transform 0.3s ease-out',
      }}
      fill="#fff"
    />
  </svg>
)
