import { useMemo } from 'react'
import NextLink from 'next/link'
import { useRouter } from 'next/router'
import { useSelector } from 'react-redux'

import { FormattedMessage, useIntl } from 'react-intl'

import { useAppDispatch } from '@/lib/core/store/store'
import { routePaths } from '@/lib/core/constants/routes'
import { useAuthenticatedSession } from '@/lib/core/hooks/useAuthenticatedSession'
import { CircleAvatar } from '@/lib/core/components/CircleAvatar'
import { useLayoutContext } from '@/lib/core/components/Layout'

import { getUser, getUserSelector } from '@/lib/features/Auth/store'

import { stringToColor } from '@/lib/utils/colors'

import {
  BridgeId,
  CompanyName,
  InitialAvatar,
  User,
  UserLink,
  UserName,
  Wrapper,
} from './SidebarAccount.styles'
import messages from './SidebarAccount.messages'

export const SidebarAccount = () => {
  const dispatch = useAppDispatch()
  const userData = useSelector(getUserSelector)
  const { formatMessage } = useIntl()
  const router = useRouter()
  const { isMenuExpanded } = useLayoutContext()
  const currentRoute = router.asPath

  const { background, color } = useMemo(() => {
    const stringPath = `${userData?.firstName}${userData?.lastName || userData?.email}`

    return stringToColor(stringPath)
  }, [userData])

  useAuthenticatedSession(() => {
    dispatch(getUser()).unwrap()
  }, [])

  if (!userData) {
    return <></>
  }

  return (
    <Wrapper>
      {isMenuExpanded && (
        <>
          {userData.organization?.name && (
            <CompanyName>
              <NextLink href={routePaths.account}>{userData.organization?.name}</NextLink>
            </CompanyName>
          )}
          {userData.organization?.id && (
            <BridgeId>
              <FormattedMessage {...messages.bridgeID} />
              {` ${userData.organization?.id}`}
            </BridgeId>
          )}
        </>
      )}
      {(userData.firstName || userData.lastName) && (
        <User>
          <UserLink
            href={routePaths.account}
            passHref
            $active={currentRoute === routePaths.account}
            $isOnlyAvatar={!isMenuExpanded}
          >
            {userData.organization?.avatar ? (
              <CircleAvatar
                src={userData.organization.avatar}
                alt={formatMessage(messages.imageAlt)}
                placeholder="empty"
                size={48}
              />
            ) : (
              <InitialAvatar $background={background} $color={color}>
                {`${userData.firstName.slice(0, 1)}`}
              </InitialAvatar>
            )}
            {isMenuExpanded && <UserName>{`${userData.firstName} ${userData.lastName}`}</UserName>}
          </UserLink>
        </User>
      )}
    </Wrapper>
  )
}
