import { ComponentPropsWithRef } from 'react'

import { NotSquareIconProps } from '@/lib/core/interfaces/common'

export const CheckmarkIcon = ({
  color = 'currentColor',
  height = '13',
  strokeWidth = '2',
  width = '19',
  ...props
}: NotSquareIconProps & ComponentPropsWithRef<'svg'>) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 19 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M17.0078 1L6.00781 12L1.00781 7"
      stroke={color}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
