import { ActionReducerMapBuilder, createReducer } from '@reduxjs/toolkit'
import dayjs from 'dayjs'

import { RequestStatus } from '@/lib/core/interfaces/common'

import { SharesState } from '@/lib/features/SmartShares/interfaces/state'

import {
  getActiveShares,
  getHistoryShares,
  getOldestShareDate,
  getSharesSharedFromMe,
  getSharesSharedToMe,
  updateSmartShareMapAccess,
} from './actions'

export const initialState: SharesState = {
  active: {
    data: {
      count: 0,
      countAll: 0,
      countUnseen: 0,
      countLent: 0,
      countBorrowed: 0,
      countOnward: 0,
      next: null,
      previous: null,
      results: [],
    },
    error: null,
    status: RequestStatus.IDLE,
  },
  history: {
    data: {
      count: 0,
      countAll: 0,
      countUnseen: 0,
      countLent: 0,
      countBorrowed: 0,
      countOnward: 0,
      next: null,
      previous: null,
      results: [],
    },
    error: null,
    status: RequestStatus.IDLE,
  },
  oldestShareDate: {
    data: dayjs().subtract(6, 'month').toISOString(),
    error: null,
    status: RequestStatus.IDLE,
  },
  sharedFromMe: {
    data: {
      count: 0,
      next: null,
      previous: null,
      results: [],
    },
    error: null,
    status: RequestStatus.IDLE,
  },
  sharedToMe: {
    data: {
      count: 0,
      next: null,
      previous: null,
      results: [],
    },
    error: null,
    status: RequestStatus.IDLE,
  },
}

export const sharesReducer = createReducer(
  initialState,
  (builder: ActionReducerMapBuilder<SharesState>) => {
    builder
      .addCase(getActiveShares.pending, (state) => {
        state.active.status = RequestStatus.LOADING
      })
      .addCase(getActiveShares.fulfilled, (state, { payload }) => {
        state.active.status = RequestStatus.SUCCEEDED
        state.active.data = payload
        state.active.error = null
      })
      .addCase(getActiveShares.rejected, (state, { payload }) => {
        state.active.status = RequestStatus.FAILED
        state.active.error = payload
      })

      .addCase(getHistoryShares.pending, (state) => {
        state.history.status = RequestStatus.LOADING
      })
      .addCase(getHistoryShares.fulfilled, (state, { payload }) => {
        state.history.status = RequestStatus.SUCCEEDED
        state.history.data = payload
        state.history.error = null
      })
      .addCase(getHistoryShares.rejected, (state, { payload }) => {
        state.history.status = RequestStatus.FAILED
        state.history.error = payload
      })

      .addCase(getSharesSharedFromMe.pending, (state) => {
        state.sharedFromMe.status = RequestStatus.LOADING
      })
      .addCase(getSharesSharedFromMe.fulfilled, (state, { payload }) => {
        state.sharedFromMe.status = RequestStatus.SUCCEEDED
        state.sharedFromMe.data = payload
        state.sharedFromMe.error = null
      })
      .addCase(getSharesSharedFromMe.rejected, (state, { payload }) => {
        state.sharedFromMe.status = RequestStatus.FAILED
        state.sharedFromMe.error = payload
      })

      .addCase(getSharesSharedToMe.pending, (state) => {
        state.sharedToMe.status = RequestStatus.LOADING
      })
      .addCase(getSharesSharedToMe.fulfilled, (state, { payload }) => {
        state.sharedToMe.status = RequestStatus.SUCCEEDED
        state.sharedToMe.data = payload
        state.sharedToMe.error = null
      })
      .addCase(getSharesSharedToMe.rejected, (state, { payload }) => {
        state.sharedToMe.status = RequestStatus.FAILED
        state.sharedToMe.error = payload
      })

      .addCase(getOldestShareDate.pending, (state) => {
        state.oldestShareDate.status = RequestStatus.LOADING
      })
      .addCase(getOldestShareDate.fulfilled, (state, { payload }) => {
        state.oldestShareDate.data = payload
        state.oldestShareDate.status = RequestStatus.SUCCEEDED
      })
      .addCase(getOldestShareDate.rejected, (state) => {
        state.oldestShareDate.status = RequestStatus.FAILED
      })

      .addCase(updateSmartShareMapAccess.fulfilled, (state, { payload }) => {
        state.active.data = {
          ...state.active.data,
          results: state.active.data.results.map((share) => ({
            ...share,
            isActive: share.id === payload.id ? payload.isActive : share.isActive,
          })),
        }
      })
  },
)
