import { AxiosError } from 'axios'
import { createAction, createAsyncThunk } from '@reduxjs/toolkit'

import { AsyncThunkReject } from '@/lib/core/interfaces/common'
import { User } from '@/lib/core/interfaces/user'

import { api } from '@/lib/features/Auth/api'
import {
  ChangePasswordError,
  ChangePasswordPayload,
  ChangePasswordResponse,
  ResetPasswordError,
  ResetPasswordPayload,
  ResetPasswordResponse,
} from '@/lib/features/Auth/interfaces'

import { getActionPrefix } from '@/lib/utils/helpers'

const actionPrefix = getActionPrefix('auth')

export const getUser = createAsyncThunk(`${actionPrefix}/get-user`, async () => {
  const { data } = await api.getUser()

  return data
})

export const setUser = createAction(`${actionPrefix}/setUser`, (payload: User) => ({
  payload,
}))

export const logout = createAction(`${actionPrefix}/logout`)

export const resetPassword = createAsyncThunk<
  ResetPasswordResponse,
  ResetPasswordPayload,
  AsyncThunkReject<ResetPasswordError>
>(`${actionPrefix}/login`, async (payload, thunkApi) => {
  try {
    const { data } = await api.resetPassword(payload)

    return data
  } catch (err) {
    const error = err as AxiosError<ResetPasswordError>

    if (!error.response) {
      throw error
    }

    return thunkApi.rejectWithValue(error.response.data)
  }
})

export const changePassword = createAsyncThunk<
  ChangePasswordResponse,
  ChangePasswordPayload,
  AsyncThunkReject<ChangePasswordError>
>(`${actionPrefix}/changePassword`, async (payload, thunkApi) => {
  try {
    const { data } = await api.changePassword(payload)

    return data
  } catch (err) {
    const error = err as AxiosError<ChangePasswordError>

    if (!error.response) {
      throw error
    }

    return thunkApi.rejectWithValue(error.response.data)
  }
})
