import { DefaultIconProps } from '@/lib/core/interfaces/common'

export const PlusCircleIcon = ({ color = 'currentColor', size = '16' }: DefaultIconProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M12 8V16" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
    <path d="M8 12H16" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
  </svg>
)
