import { createSelector } from '@reduxjs/toolkit'

import { RootState } from '@/lib/core/interfaces/store'

import { ContactsState } from '@/lib/features/Contacts/interfaces'

const featureStateSelector = (state: RootState): ContactsState => state.contacts

export const getContactsSharedFromMeSelector = createSelector(
  featureStateSelector,
  (state) => state.contactsSharedFromMe,
)
export const getContactsSharedToMeSelector = createSelector(
  featureStateSelector,
  (state) => state.contactsSharedToMe,
)
export const getMyContactsSelector = createSelector(
  featureStateSelector,
  (state) => state.myContacts,
)
export const getMyInvitationsSelector = createSelector(
  featureStateSelector,
  (state) => state.myInvitations,
)
export const searchMyContactsSelector = createSelector(
  featureStateSelector,
  (state) => state.searchMyContacts,
)
