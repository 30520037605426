import { ComponentPropsWithRef } from 'react'

export const MapSidebarVehicleLostContactIcon = (props: ComponentPropsWithRef<'svg'>) => (
  <svg
    width={24}
    height={25}
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx={12} cy={12.418} r={12} fill="#fff" />
    <path
      d="M6.859 17.584a.353.353 0 0 0 .44.473l4.345-1.507V6.419zm5.5-1.034 4.344 1.507a.353.353 0 0 0 .44-.473L12.36 6.42z"
      fill="#314663"
    />
    <circle cx={12} cy={12.418} r={12} fill="#fff" />
    <path
      d="M19.5 12.418a7.5 7.5 0 1 0-15 0 7.5 7.5 0 0 0 15 0ZM6.675 17.746l10.65-10.65"
      stroke="#314663"
      strokeWidth="2.25"
    />
    <path
      d="M19.5 12.418a7.5 7.5 0 1 0-15 0 7.5 7.5 0 0 0 15 0ZM6.675 17.746l10.65-10.65"
      stroke="#314663"
    />
  </svg>
)
