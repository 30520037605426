import { ReactNode } from 'react'
import { useRouter } from 'next/router'
import NextLink from 'next/link'

import { StyledLi } from './SidebarItem.styles'

type SidebarItemProps = {
  children: ReactNode
  href: string
}

export const SidebarItem = ({ children, href }: SidebarItemProps) => {
  const { asPath } = useRouter()

  const isActive = () => {
    if (href === '/') {
      return asPath === '/'
    }

    return asPath.startsWith(href)
  }

  return (
    <StyledLi $active={isActive()}>
      <NextLink href={href} passHref>
        {children}
      </NextLink>
    </StyledLi>
  )
}
