import styled, { css, keyframes } from 'styled-components'

import { shadeHexColor } from '@/lib/utils/colors'

type LoaderWrapperProps = {
  isLinear: boolean
  size?: string
}

const circularRotateKeyframe = keyframes`
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
`

export const LoaderWrapper = styled.span<LoaderWrapperProps>`
  position: relative;
  ${({ isLinear }) =>
    isLinear &&
    css`
      display: block;
      width: 100%;
      height: 2px;
      margin: ${({ theme: { layout } }) => layout.spacing(1)} 0;
    `}
  ${({ isLinear, size }) =>
    !isLinear &&
    css`
      display: inline-block;
      height: ${size || '100%'};
      width: ${size || 'auto'};
      /**
    * Hey you!
    *
    * If you are here to copy/paste the animation to adjust one of the properties in your project
    * Please also prepare a MR with some util for animation to easily configure all of them via props
    * The default animation for this element should stay the same
    *
    **/
      animation: ${circularRotateKeyframe} 1.4s linear infinite;
    `}
`

export const LoaderLinearBackground = styled.span`
  display: block;
  width: 100%;
  height: 2px;
  background-color: ${({ theme: { colors } }) => shadeHexColor(colors.primary, 0.5)};
`

const linearLoading = keyframes`
  0% {
    left: 0;
    width: 0%;
  }

  20% {
    left: 0%;
    width: 40%;
  }

  40% {
    left: 60%;
    width: 40%;
  }

  50% {
    left: 100%;
    width: 0;
  }

  55% {
    left: 0%;
    width: 0%;
  }

  60% {
    left: 0%;
    width: 20%;
  }

  80% {
    left: 40%;
    width: 30%;
  }

  100% {
    left: 100%;
    width: 0%;
  }
`

export const LoaderLinearLoader = styled.span`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 2px;
  box-shadow: 0 0 8px 0 ${({ theme: { colors } }) => colors.primary};
  margin: auto;
  background-color: ${({ theme: { colors } }) => colors.primary};

  /**
  * Hey you!
  *
  * If you are here to copy/paste the animation to adjust one of the properties in your project
  * Please also prepare a MR with some animation util to easily configure all of them via props
  * The default animation for this element should stay the same
  *
  **/
  animation: ${linearLoading} 4s ease infinite;
`

export const LoaderCircularIcon = styled.svg`
  display: block;
  width: auto;
  height: 100%;
`

const circularDashKeyframe = keyframes`
  0% {
    stroke-dasharray: 1px, 200px;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 100px, 200px;
    stroke-dashoffset: -15px;
  }

  100% {
    stroke-dasharray: 100px, 200px;
    stroke-dashoffset: -125px;
  }
`

export type LoaderCircularCircleProps = {
  color?: string
}

export const LoaderCircularCircle = styled.circle<LoaderCircularCircleProps>`
  stroke: ${({ theme, color }) => color || theme.colors.primary};
  stroke-dasharray: 80px, 200px;
  stroke-dashoffset: 0;

  /**
  * Hey you!
  *
  * If you are here to copy/paste the animation to adjust one of the properties in your project
  * Please also prepare a MR with some animation util to easily configure all of them via props
  * The default animation for this element should stay the same
  *
  **/
  animation: ${circularDashKeyframe} 1.4s ease-in-out infinite;
`
