import { DefaultIconProps } from '@/lib/core/interfaces/common'

export const MapPinOffIcon = ({
  color = 'currentColor',
  size = '16',
  ...props
}: DefaultIconProps) => (
  <svg
    {...props}
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.61984 3.62012C3.00076 4.51565 2.6683 5.5781 2.6665 6.66678C2.6665 10.6668 7.99984 14.6668 7.99984 14.6668C9.23872 13.6912 10.3576 12.5723 11.3332 11.3335"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.7867 9.01327C13.1319 8.2782 13.3182 7.47857 13.3333 6.6666C13.3333 5.25211 12.7714 3.89556 11.7712 2.89537C10.771 1.89517 9.41448 1.33327 7.99999 1.33327C7.18598 1.33122 6.38262 1.51836 5.65332 1.87994"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.08677 6.08667C6.02728 6.27418 5.99803 6.46996 6.0001 6.66667C6.0001 7.1971 6.21082 7.70581 6.58589 8.08088C6.96096 8.45596 7.46967 8.66667 8.0001 8.66667C8.19681 8.66875 8.39259 8.63949 8.5801 8.58"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.93333 6.16656C9.84483 5.82039 9.66521 5.50424 9.41314 5.251C9.16107 4.99776 8.84576 4.81667 8.5 4.72656"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1.3335 1.33325L14.6668 14.6666"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
