import { ComponentPropsWithRef } from 'react'
import * as React from 'react'

import { DefaultIconProps } from '@/lib/core/interfaces/common'

export const FilterMenuIcon = ({
  color = 'currentColor',
  size = '16',
  strokeWidth = '1.156',
  ...props
}: DefaultIconProps & ComponentPropsWithRef<'svg'>) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 19 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M16.435 3.332h-5.396m-3.081 0H2.563M16.438 9.5H9.5m-3.083 0H2.563m13.877 6.168h-3.854m-3.086 0H2.563M11.04 1.793v3.083M6.414 7.957v3.083m6.172 3.085v3.083"
      stroke={color}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
export default FilterMenuIcon
