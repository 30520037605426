import { ListFilters } from '@/lib/core/interfaces/common'

import {
  AcceptConnectionRequestPayload,
  AcceptConnectionRequestResponse,
  ContactsSharedFromMeResponse,
  ContactsSharedToMeResponse,
  CreateMyInvitationPayload,
  CreateMyInvitationResponse,
  MyInvitationsPayload,
  MyInvitationsResponse,
  ResendMyInvitationPayload,
  RevokeMyInvitationPayload,
  VerifyConnectionRequestPayload,
  VerifyConnectionRequestResponse,
} from '@/lib/features/Contacts/interfaces'

import { request } from '@/lib/utils/request'
import { makeServerSideRequest } from '@/lib/utils/serverSideRequest'

const baseUrl = 'v1/organizations/contacts'

export const makeApi = (instance = request) => ({
  acceptConnectionRequest: (data: AcceptConnectionRequestPayload) =>
    instance.post<AcceptConnectionRequestResponse>(
      `${baseUrl}/my/invitations/invitation_confirm/`,
      data,
    ),
  createMyInvitation: (data: CreateMyInvitationPayload) =>
    instance.post<CreateMyInvitationResponse>(`${baseUrl}/my/invitations/`, data),
  getContactsSharedFromMe: () =>
    instance.get<ContactsSharedFromMeResponse>(`${baseUrl}/my/shared-from-me/`),
  getContactsSharedToMe: () =>
    instance.get<ContactsSharedToMeResponse>(`${baseUrl}/my/shared-to-me/`),
  getMyContacts: (params: ListFilters) =>
    instance.get<MyInvitationsResponse>(`${baseUrl}/my/`, { params }),
  getMyInvitations: (params: MyInvitationsPayload) =>
    instance.get<MyInvitationsResponse>(`${baseUrl}/my/invitations/`, { params }),
  resendMyInvitation: (invitationId: ResendMyInvitationPayload) =>
    instance.post<CreateMyInvitationResponse>(
      `${baseUrl}/my/invitations/${invitationId}/resend_invitation/`,
    ),
  revokeMyInvitation: (invitationId: RevokeMyInvitationPayload) =>
    instance.delete<CreateMyInvitationResponse>(`${baseUrl}/my/invitations/${invitationId}/`),
  verifyConnectionRequest: (data: VerifyConnectionRequestPayload) =>
    instance.post<VerifyConnectionRequestResponse>(
      `${baseUrl}/my/invitations/invitation_confirm_validate/`,
      data,
    ),
})

export const api = makeApi()
export const makeServerSideApi = (token?: string) => makeApi(makeServerSideRequest(token))
