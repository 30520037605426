export type MapVehicleIconProps = {
  rotate: number
  color?: string
  height?: number
  width?: number
}

export const MapVehicleIcon = ({ rotate, color = "currentColor", height, width }: MapVehicleIconProps) => (
  <svg
    width={width ?? '54'}
    height={height ?? '66'}
    viewBox="0 0 54 66"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    color={color}
  >
    <g filter="url(#filter0_d_5939_9759)">
      <path
        d="M51 24C51 42 27 60 27 60C27 60 3 42 3 24C3 17.6348 5.52856 11.5303 10.0294 7.02944C14.5303 2.52856 20.6348 0 27 0C33.3652 0 39.4697 2.52856 43.9706 7.02944C48.4714 11.5303 51 17.6348 51 24Z"
        fill="currentColor"
      />
      <path
        d="M49.5 24C49.5 32.4075 43.8443 41.0424 37.8789 47.7534C34.9314 51.0694 31.9792 53.8376 29.7622 55.7774C28.6548 56.7464 27.7336 57.5063 27.0917 58.0221C27.0604 58.0472 27.0299 58.0717 27 58.0957C26.9701 58.0717 26.9396 58.0472 26.9083 58.0221C26.2664 57.5063 25.3452 56.7464 24.2378 55.7774C22.0208 53.8376 19.0686 51.0694 16.1211 47.7534C10.1557 41.0424 4.5 32.4075 4.5 24C4.5 18.0326 6.87053 12.3097 11.0901 8.0901C15.3097 3.87053 21.0326 1.5 27 1.5C32.9674 1.5 38.6903 3.87053 42.9099 8.0901C47.1295 12.3097 49.5 18.0326 49.5 24Z"
        stroke="white"
        strokeWidth="3"
      />
    </g>
    <g
      style={{
        transform: `rotate(${rotate}deg)`,
        transformOrigin: '27px 27px',
        transition: 'transform 0.5s ease',
      }}
      clipPath="url(#clip0_5939_9759)"
    >
      <path
        d="M17.9464 33.2056C17.8534 33.4225 17.8972 33.6738 18.058 33.8465C18.2189 34.0193 18.4665 34.0808 18.6894 34.0034L26.0233 31.4599L26.0235 14.3593L17.9464 33.2056Z"
        fill="white"
      />
      <path
        d="M35.8974 33.2056C35.9903 33.4225 35.9465 33.6738 35.7857 33.8465C35.6249 34.0193 35.3772 34.0808 35.1543 34.0034L27.8204 31.4599L27.8202 14.3593L35.8974 33.2056Z"
        fill="white"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_5939_9759"
        x="0"
        y="0"
        width="54"
        height="66"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="3" />
        <feGaussianBlur stdDeviation="1.5" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_5939_9759" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_5939_9759"
          result="shape"
        />
      </filter>
      <clipPath id="clip0_5939_9759">
        <rect
          width="22.1565"
          height="22.1565"
          fill="white"
          transform="translate(15.9199 13.9219)"
        />
      </clipPath>
    </defs>
  </svg>
)
