import Link from 'next/link'

import {
  FloatingWrapper,
  Footer,
} from '@/lib/core/components/Layout/SidebarFooter/SidebarFooter.styles'
import { SidebarAccount } from '@/lib/core/components/Layout/SidebarAccount/SidebarAccount'
import { useLayoutContext } from '@/lib/core/components/Layout'

export const SidebarFooter = () => {
  const { isMenuExpanded } = useLayoutContext()

  return (
    <FloatingWrapper>
      <SidebarAccount />
      {isMenuExpanded && (
        <Footer>
          A product of <span>&nbsp;</span><Link href="https://gometroapp.com/" target="_blank" rel="noreferrer">GoMetro</Link>
        </Footer>
      )}
    </FloatingWrapper>
  )
}
