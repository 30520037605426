import { ActionReducerMapBuilder, createReducer } from '@reduxjs/toolkit'

import { RequestStatus } from '@/lib/core/interfaces/common'

import { ContactsState } from '@/lib/features/Contacts/interfaces'

import {
  getContactsSharedFromMe,
  getContactsSharedToMe,
  getMyContacts,
  getMyInvitations,
  searchMyContacts,
} from './actions'

export const initialState: ContactsState = {
  contactsSharedFromMe: {
    data: {
      count: 0,
      next: null,
      previous: null,
      results: [],
    },
    error: null,
    status: RequestStatus.IDLE,
  },
  contactsSharedToMe: {
    data: {
      count: 0,
      next: null,
      previous: null,
      results: [],
    },
    error: null,
    status: RequestStatus.IDLE,
  },
  myContacts: {
    data: {
      count: 0,
      next: null,
      previous: null,
      results: [],
    },
    error: null,
    status: RequestStatus.IDLE,
  },
  myInvitations: {
    data: {
      count: 0,
      next: null,
      previous: null,
      results: [],
    },
    error: null,
    status: RequestStatus.IDLE,
  },
  searchMyContacts: {
    data: {
      count: 0,
      next: null,
      previous: null,
      results: [],
    },
    error: null,
    status: RequestStatus.IDLE,
  },
}

export const contactsReducer = createReducer(
  initialState,
  (builder: ActionReducerMapBuilder<ContactsState>) => {
    builder
      .addCase(getMyInvitations.pending, (state) => {
        state.myInvitations.status = RequestStatus.LOADING
      })
      .addCase(getMyInvitations.fulfilled, (state, { payload }) => {
        state.myInvitations.status = RequestStatus.SUCCEEDED
        state.myInvitations.data = payload
        state.myInvitations.error = null
      })
      .addCase(getMyInvitations.rejected, (state, { payload }) => {
        state.myInvitations.status = RequestStatus.FAILED
        state.myInvitations.error = payload
      })

      .addCase(getContactsSharedFromMe.pending, (state) => {
        state.contactsSharedFromMe.status = RequestStatus.LOADING
      })
      .addCase(getContactsSharedFromMe.fulfilled, (state, { payload }) => {
        state.contactsSharedFromMe.status = RequestStatus.SUCCEEDED
        state.contactsSharedFromMe.data = payload
        state.contactsSharedFromMe.error = null
      })
      .addCase(getContactsSharedFromMe.rejected, (state, { payload }) => {
        state.contactsSharedFromMe.status = RequestStatus.FAILED
        state.contactsSharedFromMe.error = payload
      })

      .addCase(getContactsSharedToMe.pending, (state) => {
        state.contactsSharedToMe.status = RequestStatus.LOADING
      })
      .addCase(getContactsSharedToMe.fulfilled, (state, { payload }) => {
        state.contactsSharedToMe.status = RequestStatus.SUCCEEDED
        state.contactsSharedToMe.data = payload
        state.contactsSharedToMe.error = null
      })
      .addCase(getContactsSharedToMe.rejected, (state, { payload }) => {
        state.contactsSharedToMe.status = RequestStatus.FAILED
        state.contactsSharedToMe.error = payload
      })

      .addCase(getMyContacts.pending, (state) => {
        state.myContacts.status = RequestStatus.LOADING
      })
      .addCase(getMyContacts.fulfilled, (state, { payload }) => {
        state.myContacts.status = RequestStatus.SUCCEEDED
        state.myContacts.data = payload
        state.myContacts.error = null
      })
      .addCase(getMyContacts.rejected, (state, { payload }) => {
        state.myContacts.status = RequestStatus.FAILED
        state.myContacts.error = payload
      })

      .addCase(searchMyContacts.pending, (state) => {
        state.searchMyContacts.status = RequestStatus.LOADING
      })
      .addCase(searchMyContacts.fulfilled, (state, { payload }) => {
        state.searchMyContacts.status = RequestStatus.SUCCEEDED
        state.searchMyContacts.data = payload
        state.searchMyContacts.error = null
      })
      .addCase(searchMyContacts.rejected, (state, { payload }) => {
        state.searchMyContacts.status = RequestStatus.FAILED
        state.searchMyContacts.error = payload
      })
  },
)
