import { ReactNode, useCallback } from 'react'

import { FormattedMessage } from 'react-intl'

import { routePaths } from '@/lib/core/constants/routes'
import {
  CheckCircleIcon,
  LayoutGridIcon,
  MapIcon,
  MergeIcon,
  TruckIcon,
} from '@/lib/core/components/Icon'
import { SidebarItem, useLayoutContext } from '@/lib/core/components/Layout'

import messages from './SidebarNav.messages'
import { SidebarLink, SidebarList, SidebarNavigation } from './SidebarNav.styles'

export const SidebarNav = () => {
  const { isMenuExpanded } = useLayoutContext()
  const renderMessage = useCallback((message: ReactNode) => isMenuExpanded && message, [isMenuExpanded])

  return (
    <SidebarNavigation>
      <SidebarList>
        <SidebarItem href={routePaths.home}>
          <SidebarLink>
            <LayoutGridIcon size="22" />
            {renderMessage(<FormattedMessage {...messages.dashboard} />)}
          </SidebarLink>
        </SidebarItem>
        <SidebarItem href={routePaths.assets}>
          <SidebarLink>
            <TruckIcon size="24" />
            {renderMessage(<FormattedMessage {...messages.assets} />)}
          </SidebarLink>
        </SidebarItem>
        <SidebarItem href={routePaths.share}>
          <SidebarLink>
            <MergeIcon />
            {renderMessage(<FormattedMessage {...messages.share} />)}
          </SidebarLink>
        </SidebarItem>
        <SidebarItem href={routePaths.contacts}>
          <SidebarLink>
            <CheckCircleIcon size="22" />
            {renderMessage(<FormattedMessage {...messages.contacts} />)}
          </SidebarLink>
        </SidebarItem>
        <SidebarItem href={routePaths.mapOverview}>
          <SidebarLink>
            <MapIcon size="22" />
            {renderMessage(<FormattedMessage {...messages.mapOverview} />)}
          </SidebarLink>
        </SidebarItem>
      </SidebarList>
    </SidebarNavigation>
  )
}
