import { createSelector } from '@reduxjs/toolkit'

import type { RootState } from '@/lib/core/interfaces/store'

import type { MapState } from '@/lib/features/Map/interfaces'

const featureSelector = (state: RootState): MapState => state.map

export const getMyTelemetryCredentialsSelector = createSelector(
  featureSelector,
  (state) => state.myTelemetryCredentials,
)
