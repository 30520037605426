import * as Sentry from '@sentry/nextjs'

export const captureError = <T extends (error: Error) => unknown | undefined>(
  callback?: T,
  log: boolean = true,
) =>
  ((error: Error) => {
    if (log) {
      console.error(error)
    }
    Sentry.captureException(error)

    return callback?.(error)
  }) as T
