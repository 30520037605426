import { ComponentPropsWithRef } from 'react'

import { DefaultIconProps } from '@/lib/core/interfaces/common'

export const OnDragTargetIcon = ({
  color = 'currentColor',
  size = '16',
  ...props
}: DefaultIconProps & ComponentPropsWithRef<'svg'>) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      stroke={color}
      strokeWidth="3.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M4 28h7.2m33.6 0H52M27.999 4v7.2m0 33.599v7.2m-.001-7.2c9.278 0 16.8-7.521 16.8-16.8S37.276 11.2 27.998 11.2s-16.8 7.521-16.8 16.8 7.522 16.799 16.8 16.799Zm4.365-21.163-8.727 8.727m0-8.727 8.727 8.727"
    />
  </svg>
)
