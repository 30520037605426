import { AxiosError } from 'axios'
import { createAsyncThunk } from '@reduxjs/toolkit'
import get from 'lodash-es/get'
import dayjs from 'dayjs'

import { AsyncThunkReject, DateISOString, SortDirection } from '@/lib/core/interfaces/common'

import { api } from '@/lib/features/SmartShares/api'
import {
  ActiveSharesPayload,
  ActiveSharesResponse,
  ActiveSharesResponseError,
  CancelActiveSharesPayload,
  CancelActiveSharesResponse,
  CancelActiveSharesResponseError,
  HistorySharesPayload,
  HistorySharesResponse,
  HistorySharesResponseError,
  OrganizationChargesPayload,
  OrganizationChargesResponse,
  OrganizationChargesResponseError,
  PatchSharePayload,
  PostSharesPayload,
  PostSharesResponse,
  PostSharesResponseError,
  ShareChargesPayload,
  ShareChargesResponse,
  ShareChargesResponseError,
  SharesSharedFromMePayload,
  SharesSharedFromMeResponse,
  SharesSharedFromMeResponseError,
  SharesSharedToMePayload,
  SharesSharedToMeResponse,
  SharesSharedToMeResponseError,
  SinglePostSharesResponse,
  UpdateMapAccessPayload,
  UpdateMapAccessResponse,
  UpdateMapAccessResponseError,
} from '@/lib/features/SmartShares/interfaces'

import { getActionPrefix } from '@/lib/utils/helpers'

const actionPrefix = getActionPrefix('assets')

export const cancelSmartShare = createAsyncThunk<
  CancelActiveSharesResponse,
  CancelActiveSharesPayload,
  AsyncThunkReject<CancelActiveSharesResponseError>
>(`${actionPrefix}/cancelActive`, async (payload, thunkApi) => {
  try {
    const { data } = await api.cancelSmartShare(payload)

    return data
  } catch (err) {
    const error = err as AxiosError<ActiveSharesResponseError>

    if (!error.response) {
      throw error
    }

    return thunkApi.rejectWithValue(error.response.data)
  }
})

export const getActiveShares = createAsyncThunk<
  ActiveSharesResponse,
  ActiveSharesPayload,
  AsyncThunkReject<ActiveSharesResponseError>
>(`${actionPrefix}/getActive`, async (payload = {}, thunkApi) => {
  try {
    const { data } = await api.getSharesActive(payload)

    return data
  } catch (err) {
    const error = err as AxiosError<ActiveSharesResponseError>

    if (!error.response) {
      throw error
    }

    return thunkApi.rejectWithValue(error.response.data)
  }
})

export const getHistoryShares = createAsyncThunk<
  HistorySharesResponse,
  HistorySharesPayload,
  AsyncThunkReject<HistorySharesResponseError>
>(`${actionPrefix}/getHistory`, async (payload = {}, thunkApi) => {
  try {
    const { data } = await api.getSharesHistory(payload)

    return data
  } catch (err) {
    const error = err as AxiosError<HistorySharesResponseError>

    if (!error.response) {
      throw error
    }

    return thunkApi.rejectWithValue(error.response.data)
  }
})

export const getSharesSharedFromMe = createAsyncThunk<
  SharesSharedFromMeResponse,
  SharesSharedFromMePayload,
  AsyncThunkReject<SharesSharedFromMeResponseError>
>(`${actionPrefix}/getSharesSharedFromMe`, async (_, thunkApi) => {
  try {
    const { data } = await api.getSharesSharedFromMe()

    return data
  } catch (err) {
    const error = err as AxiosError<SharesSharedFromMeResponseError>

    if (!error.response) {
      throw error
    }

    return thunkApi.rejectWithValue(error.response.data)
  }
})

export const getSharesSharedToMe = createAsyncThunk<
  SharesSharedToMeResponse,
  SharesSharedToMePayload,
  AsyncThunkReject<SharesSharedToMeResponseError>
>(`${actionPrefix}/getSharesSharedToMe`, async (_, thunkApi) => {
  try {
    const { data } = await api.getSharesSharedToMe()

    return data
  } catch (err) {
    const error = err as AxiosError<SharesSharedToMeResponseError>

    if (!error.response) {
      throw error
    }

    return thunkApi.rejectWithValue(error.response.data)
  }
})

export const shareAssetsToContact = createAsyncThunk<
  PostSharesResponse,
  PostSharesPayload,
  AsyncThunkReject<PostSharesResponseError>
>(`${actionPrefix}/addAssetVehicle`, async (payload, thunkApi) => {
  try {
    const { data } = await api.shareAssets(payload)

    return data
  } catch (err) {
    const error = err as AxiosError<PostSharesResponseError>

    if (!error.response) {
      throw error
    }

    return thunkApi.rejectWithValue(error.response.data)
  }
})

export const extendShareAsset = createAsyncThunk<
  SinglePostSharesResponse,
  PatchSharePayload,
  AsyncThunkReject<PostSharesResponseError>
>(`${actionPrefix}/addAssetVehicle`, async (payload, thunkApi) => {
  try {
    const { data } = await api.updateShareAssets(payload.id, payload.share)

    return data
  } catch (err) {
    const error = err as AxiosError<PostSharesResponseError>

    if (!error.response) {
      throw error
    }

    return thunkApi.rejectWithValue(error.response.data)
  }
})

export const getSharesCharges = createAsyncThunk<
  ShareChargesResponse,
  ShareChargesPayload,
  AsyncThunkReject<ShareChargesResponseError>
>(`${actionPrefix}/getShareCharges`, async (payload, thunkApi) => {
  try {
    const { data } = await api.getSharesCharges(payload)

    return data
  } catch (err) {
    const error = err as AxiosError<ShareChargesResponseError>

    if (!error.response) {
      throw error
    }

    return thunkApi.rejectWithValue(error.response.data)
  }
})

export const getOrganizationCharges = createAsyncThunk<
  OrganizationChargesResponse,
  OrganizationChargesPayload,
  AsyncThunkReject<OrganizationChargesResponseError>
>(`${actionPrefix}/getOrganizationCharges`, async (payload, thunkApi) => {
  try {
    const { data } = await api.getOrganizationCharges(payload)

    return data
  } catch (err) {
    const error = err as AxiosError<OrganizationChargesResponseError>

    if (!error.response) {
      throw error
    }

    return thunkApi.rejectWithValue(error.response.data)
  }
})

export const getOldestShareDate = createAsyncThunk<
  DateISOString,
  void,
  AsyncThunkReject<HistorySharesResponseError>
>(`${actionPrefix}/getOldestShare`, async (_, thunkApi) => {
  try {
    const { data } = await api.getShares({
      ordering: {
        date_from: SortDirection.ASC,
      },
      pageSize: 1,
    })

    return get(data, 'results.[0].dateFrom', dayjs().subtract(3, 'month').toISOString())
  } catch (err) {
    const error = err as AxiosError<HistorySharesResponseError>

    if (!error.response) {
      throw error
    }

    return thunkApi.rejectWithValue(error.response.data)
  }
})

export const updateSmartShareMapAccess = createAsyncThunk<
  UpdateMapAccessResponse,
  UpdateMapAccessPayload,
  AsyncThunkReject<UpdateMapAccessResponseError>
>(`${actionPrefix}/updateMapAccess`, async (payload, thunkApi) => {
  try {
    const { data } = await api.updateSmartShareMapAccess(payload)

    return data
  } catch (err) {
    const error = err as AxiosError<UpdateMapAccessResponseError>

    if (!error.response) {
      throw error
    }

    return thunkApi.rejectWithValue(error.response.data)
  }
})
