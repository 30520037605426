import React from 'react'

import { SidebarWrapper } from '@/lib/core/components/Layout/Layout.styles'
import { SidebarFooter, SidebarNav } from '@/lib/core/components/Layout'

export const Sidebar = () => (
  <SidebarWrapper>
    <SidebarNav />
    <SidebarFooter />
  </SidebarWrapper>
)
