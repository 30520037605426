import { useRouter } from 'next/router'
import merge from 'lodash-es/merge'
import { DefaultTheme } from 'styled-components'

import { isLocaleRTL } from '@/lib/utils/helpers'

import { theme } from '@/lib/themes/main'

import { ThemeDirection } from '../constants/constants'
type DeepPartial<T> = {
  [P in keyof T]?: DeepPartial<T[P]>;
};
export const useTheme = (overwritenData?: DeepPartial<DefaultTheme>) => {
  const { locale } = useRouter()
  const localTheme = { ...theme }

  return merge(localTheme, {
    ...overwritenData,
    direction: isLocaleRTL(locale) ? ThemeDirection.RTL : ThemeDirection.LTR,
  })
}
