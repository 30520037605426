import { ComponentPropsWithRef } from 'react'

import { DefaultIconProps } from '@/lib/core/interfaces/common'

export const ThickPlusCircleIcon = ({ color = 'currentColor', size = '16', ...props }: DefaultIconProps & ComponentPropsWithRef<'svg'>) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g
      clipPath="url(#a)"
      stroke={color}
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M7.999 14.665a6.667 6.667 0 1 0 0-13.333 6.667 6.667 0 0 0 0 13.333M8 5.332v5.333M5.332 8h5.333" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill={color} d="M0 0h16v16H0z" />
      </clipPath>
    </defs>
  </svg>
)
