import { MyTelemetryCredentials } from '@/lib/features/Map/interfaces'

import { request, useNetworkRequest } from '@/lib/utils/request'

const makeApi = (instance = request) => ({
  getMyTelemetry: () => instance.get<MyTelemetryCredentials>('v1/organizations/my/telemetry/'),
})

export const api = makeApi()


export const useTelemetryCredentials = () =>
  useNetworkRequest<MyTelemetryCredentials>('v1/organizations/my/telemetry/')
