import { DefaultIconProps } from '@/lib/core/interfaces/common'

export const CloudIcon = ({ color = 'currentColor', size = '16' }: DefaultIconProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.10562 5.26631L8.34841 5.71529L8.79228 5.46328C9.12627 5.27367 9.50739 5.16659 9.91288 5.16659C10.931 5.16659 11.8165 5.84867 12.1467 6.83042L12.2546 7.15116L12.5925 7.17021C13.8219 7.23953 14.8332 8.31207 14.8332 9.66658C14.8332 11.0685 13.7516 12.1666 12.4636 12.1666H3.53607C2.24811 12.1666 1.1665 11.0685 1.1665 9.66658C1.1665 8.56367 1.84061 7.64177 2.75616 7.30508L3.10054 7.17843L3.08301 6.81192C3.08071 6.76382 3.07955 6.71537 3.07955 6.66658C3.07955 5.0806 4.3039 3.83325 5.76795 3.83325C6.76063 3.83325 7.63824 4.40203 8.10562 5.26631Z"
      stroke={color}
    />
  </svg>
)
