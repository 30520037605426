import styled from 'styled-components'

export const FloatingWrapper = styled.div`
  position: sticky;
  bottom: 0;
`

export const Footer = styled.footer`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  align-self: flex-end;
  width: 100%;
  height: ${({ theme: { layout } }) => layout.spacing(6)};
  padding: ${({ theme: { layout } }) => `${layout.spacing(0.5)} ${layout.spacing(4)}`};
  background-color: rgba(255, 255, 255, 0.1);
  font-size: 14px;

  a {
    color: ${({ theme: { colors } }) => colors.textOpposite};
    text-decoration: none;

    &::after {
      content: '';
      position: absolute;
      bottom: -1px;
      left: 0;
      width: 100%;
      border-bottom: 1px solid;
    }

    &:hover::after {
      border-color: transparent;
    }
  }
`
