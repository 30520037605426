import { ComponentPropsWithRef } from 'react'

import { DefaultIconProps } from '@/lib/core/interfaces/common'

export const HeadingIcon = ({
  color = 'currentColor',
  size = 15,
  ...props
}: DefaultIconProps & ComponentPropsWithRef<'svg'>) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M2.019 12.978a.364.364 0 0 0 .453.488l4.482-1.538.03-10.444Zm10.963.032a.364.364 0 0 1-.456.486L8.053 11.93l.029-10.443Z"
      fill={color}
    />
  </svg>
)
