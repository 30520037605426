import { DefaultIconProps } from '@/lib/core/interfaces/common'

export const TeardropPinIcon = ({ color = 'currentColor', size = '16' }: DefaultIconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.3346 6.66536C13.3346 10.6654 8.0013 14.6654 8.0013 14.6654C8.0013 14.6654 2.66797 10.6654 2.66797 6.66536C2.66797 5.25088 3.22987 3.89432 4.23007 2.89413C5.23026 1.89393 6.58681 1.33203 8.0013 1.33203C9.41579 1.33203 10.7723 1.89393 11.7725 2.89413C12.7727 3.89432 13.3346 5.25088 13.3346 6.66536Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.0013 8.66536C9.10587 8.66536 10.0013 7.76993 10.0013 6.66536C10.0013 5.56079 9.10587 4.66536 8.0013 4.66536C6.89673 4.66536 6.0013 5.56079 6.0013 6.66536C6.0013 7.76993 6.89673 8.66536 8.0013 8.66536Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
