import { ComponentPropsWithRef } from 'react'
import * as React from 'react'

import { DefaultIconProps } from '@/lib/core/interfaces/common'
export const LayerIcon = ({
  color = 'currentColor',
  size = '24',
  ...props
}: DefaultIconProps & ComponentPropsWithRef<'svg'>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    stroke={color}
    strokeWidth={2}
    strokeLinecap="round"
    strokeLinejoin="round"
    {...props}
  >
    <path d="M12.83 2.18a2 2 0 0 0-1.66 0L2.6 6.08a1 1 0 0 0 0 1.83l8.58 3.91a2 2 0 0 0 1.66 0l8.58-3.9a1 1 0 0 0 0-1.83ZM22 17.65l-9.17 4.16a2 2 0 0 1-1.66 0L2 17.65m20-5-9.17 4.16a2 2 0 0 1-1.66 0L2 12.65" />
  </svg>
)
export default LayerIcon
