import {
  DateString,
  DetailResponseError,
  FormFieldsResponseError,
  ListFilters,
  PaginatedList,
  ResponseError,
} from '@/lib/core/interfaces/common'
import { Organization } from '@/lib/core/interfaces/organization'

import { TrackingSource } from '@/lib/features/Assets/interfaces'
import { ShareId } from '@/lib/features/SmartShares/interfaces'

export type AssetId = number
export type ObjectId = number
export type BridgeId = string

export enum BridgeStatus {
  BORROWED = 'borrowed',
  LENT = 'lent',
}
export enum AssetContentType {
  PTU = 'portabletrackingunit',
  VEHICLE = 'vehicle',
}

export type Asset = {
  bridgeDateFrom: DateString | null
  bridgeDateTo: DateString | null
  bridgeId: BridgeId
  bridgeStatus: BridgeStatus | null
  contentType: AssetContentType
  description: string
  id: AssetId
  make: string | null
  mapIsActive: boolean | null
  model: string | null
  objectId: ObjectId
  owner: Organization
  plateNumber: string
  trackingIdentifier: string
  trackingSource: TrackingSource
  vin: string | null
  reference: string
  vehicleBridgeId: string
}

/*
  ASSET has:
    id: AssetId;
    objectId: ObjectId;

  When we create a Vehicle, the API creates two objects: the Vehicle and the Asset
  They are in a relation:
    Asset.id === 1 // new autoincrement id, this is an inditifier for Asset object only
    Vehicle.id === 2 // new autoincrement id, this is an inditifier for Vehicle object only
    Asset.object_id === Vehicle.id
    Vehicle.object_id === Asset.id
*/

export type Vehicle = Omit<
  Asset,
  | 'bridgeDateFrom'
  | 'bridgeDateTo'
  | 'bridgeStatus'
  | 'contentType'
  | 'id'
  | 'mapIsActive'
  | 'objectId'
  | 'vehicleBridgeId'
> & {
  id: ObjectId // yes, this is ObjectId
  objectId: AssetId // and yes, there goes the AssetId
}

export type CurrentShare = {
  assetId: AssetId
  bridgeId: BridgeId
  bridgeStatus: BridgeStatus
  dateFrom: Asset['bridgeDateFrom']
  dateTo: Asset['bridgeDateTo']
  reference: string
  id: ShareId
  isActive: boolean
}

export type SingleVehicle = Vehicle & {
  // TODO: remove optionality once https://app.clickup.com/t/86952wa13 completed
  vehicleBridgeId?: string
  currentShares?: Array<CurrentShare>
}

export type PortableTrackingUnit = Omit<
  Vehicle,
  'id' | 'make' | 'mapIsActive' | 'model' | 'objectId' | 'plateNumber' | 'vin'
> & {
  id: ObjectId // yes, this is ObjectId
  objectId: AssetId // and yes, there goes the AssetId
}

export type SinglePtu = PortableTrackingUnit & {
  currentShares?: Array<CurrentShare>
}

export type AssetsFilters = ListFilters & {
  bridgeIsActiveMap?: boolean
  excludeBridged?: boolean
  excludeMyVehicles?: boolean
  excludePtu?: boolean
  search?: string
}

export type AssetsPayload = AssetsFilters
export type AssetsResponse = PaginatedList<Asset>
export type AssetsResponseError = ResponseError

export type MyVehiclesPayload = ListFilters
export type MyVehiclesResponse = PaginatedList<Vehicle>
export type MyVehiclesResponseError = ResponseError

export type BridgedFleetPayload = ListFilters
export type BridgedFleetResponse = PaginatedList<Asset>
export type BridgedFleetResponseError = ResponseError

export type PTUPayload = ListFilters
export type PTUResponse = PaginatedList<Asset>
export type PTUResponseError = ResponseError

export type AssetPayload = AssetId
export type AssetResponse = Asset
export type AssetResponseError = ResponseError

export type SingleVehicleResponse = SingleVehicle
export type SingleVehicleResponseError = ResponseError

export type SinglePtuResponse = SinglePtu
export type SinglePtuResponseError = ResponseError

export type AddAssetVehiclePayload = {
  description: string
  make?: string
  model?: string
  plateNumber: string
  trackingIdentifier: string
  trackingSourceId: number
  vin?: string
}
export type AddAssetVehicleResponse = {
  bridgeId: BridgeId
  description: string
  id: ObjectId // yes, this is ObjectId
  make: string
  model: string
  objectId: AssetId // and yes, there goes the AssetId
  plateNumber: string
  vin: string
}
export type AddAssetVehicleError = FormFieldsResponseError

export type AddAssetPTUPayload = {
  description: string
  trackingIdentifier: string
  trackingSourceId: number
}
export type AddAssetPTUResponse = {
  bridgeId: BridgeId
  description: string
  id: ObjectId
  trackingSource: TrackingSource
}
export type AddAssetPTUError = FormFieldsResponseError

export type DeleteAssetPayload = AssetId
export type DeleteAssetResponse = void
export type DeleteAssetResponseError = DetailResponseError | ResponseError

export type PatchMyVehiclePayload = {
  objectId: ObjectId
  description: string
  make: string
  model: string
  plateNumber: string
  trackingIdentifier: string
  trackingSourceId: number
  vin: string
}
export type PatchMyVehicleResponse = AddAssetVehicleResponse
export type PatchMyVehicleResponseError = FormFieldsResponseError

export type PatchMyPtuPayload = {
  description: string
  objectId: ObjectId
  trackingIdentifier: string
  trackingSourceId: number
}
export type PatchMyPtuResponse = AddAssetPTUResponse
export type PatchMyPtuResponseError = FormFieldsResponseError
