import { DefaultIconProps } from '@/lib/core/interfaces/common'

import { getStrokeWidth } from '@/lib/utils/icon'

export const ForwardIcon = ({
  color = 'currentColor',
  size = '16',
  strokeWidth = getStrokeWidth(size),
}: DefaultIconProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.25 10.8325L14 7.08252L10.25 3.33252"
      stroke={color}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2 11.5825V10.0825C2 9.28687 2.31607 8.52381 2.87868 7.9612C3.44129 7.39859 4.20435 7.08252 5 7.08252H14"
      stroke={color}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
