import { trimEnd } from 'lodash-es'

import { FeatureNames } from '@/lib/core/interfaces/store'

export const getActionPrefix = (featureName: FeatureNames) => featureName.toUpperCase()

export const isLocaleRTL = (locale: string | undefined) => {
  if (!locale) {
    return false
  }

  return ['ar', 'arc', 'dv', 'fa', 'ha', 'he', 'khw', 'ks', 'ku', 'ps', 'ur', 'yi'].includes(
    locale.split('-')[0],
  )
}

export const suffix = (str?: string, suffix: string = '/'): string =>
  str ? trimEnd(str, suffix) + suffix : ''
