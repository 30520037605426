import styled, { css } from 'styled-components'

import { H1 } from '@/lib/core/components/Text'
import { LayoutContextType } from '@/lib/core/components/Layout/utils'

export const LoadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;

  & > * {
    width: 48px;
    height: 48px;
  }
`

export const PageWrapper = styled.div<{ $isMenuExpanded: LayoutContextType['isMenuExpanded'] }>`
  display: grid;
  grid-template-rows: ${({ theme: { layout } }) => layout.spacing(8)} auto;
  grid-template-columns: ${({ $isMenuExpanded }) =>
      $isMenuExpanded ? 'minmax(290px, 1fr)' : '75px'} 9fr;
  grid-template-areas:
    'topbar topbar'
    'sidebar main';
  min-height: 100vh;
`

export const TopBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  grid-area: topbar;
  padding: ${({ theme: { layout } }) => layout.spacing(3)} 37px;
  background: ${({ theme: { colors } }) => colors.backgroundContent};
`

export const SidebarWrapper = styled.div`
  --headingsColor: ${({ theme: { colors } }) => colors.textOpposite};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  grid-area: sidebar;
  background: ${({ theme: { colors } }) => colors.backgroundOpposite};
  color: ${({ theme: { colors } }) => colors.textOpposite};
  font-family: ${({ theme: { fontFamily } }) => fontFamily.default};
`

export const Main = styled.main`
  display: flex;
  flex-direction: column;
  grid-area: main;
`

export type SectionProps = {
  $margin?: number
  $padding?: number
}

export const Section = styled.div<SectionProps>`
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;
  ${({ $margin }) =>
    typeof $margin === 'number' &&
    css`
      margin: ${({ theme: { layout } }) => `${layout.spacing($margin)} 0`};
    `}
  ${({ $padding = 3 }) =>
    typeof $padding === 'number' &&
    css`
      padding: ${({ theme: { layout } }) => `0 ${layout.spacing($padding)}`};
    `}
`

export const SectionContent = styled.div`
  padding: ${({ theme: { layout } }) => layout.spacing(4)};
  background: ${({ theme: { colors } }) => colors.backgroundContent};
`

export const PageHeader = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: ${({ theme: { layout } }) => layout.spacing(1)};
  padding: ${({ theme: { layout } }) => `${layout.spacing(5)} ${layout.spacing(4)}`};
`

export const Location = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme: { layout } }) => layout.spacing(1)};
`

export const Actions = styled.div`
  align-self: stretch;
`

export const PageTitle = styled(H1)`
  margin: 0;
  font-size: 1.125rem;
  line-height: 1.2;
`
