import { MakeFormState } from '@/lib/core/interfaces/forms'
import { SelectOption } from '@/lib/core/components/Select'
import { RequestStatus } from '@/lib/core/interfaces/common'

import {
  AddAssetPTUPayload,
  AddAssetVehicleError,
  AddAssetVehiclePayload,
} from '@/lib/features/Assets/interfaces'

export enum FormType {
  SELECT_TYPE = 'selectType',
  VEHICLE_BASIC = 'vehicleBasic',
  VEHICLE_DETAIL = 'vehicleDetail',
  PTU = 'ptu',
}

export type VehicleBasicFormState = MakeFormState<
  AddAssetVehiclePayload,
  'description' | 'make' | 'vin'
>

export type VehicleDetailFormState = MakeFormState<
  AddAssetVehiclePayload,
  'model' | 'plateNumber' | 'trackingIdentifier' | 'trackingSourceId'
>

export type VehicleFormState = VehicleBasicFormState & VehicleDetailFormState

export type PTUFormState = MakeFormState<AddAssetPTUPayload>

export type FormSteps = {
  current: number
  total: number
}

export type AddFormState = {
  errors: AddAssetVehicleError | null
  formType: FormType
  isOpen: boolean
  lockedForms: Array<FormType>
  selectedOption: SelectOption | null
  state: Partial<VehicleFormState> | null
  status: RequestStatus
  steps: FormSteps | null
}
