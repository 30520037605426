import styled, { css } from 'styled-components'

export type HeadingSizeUnion = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'

export type HeadingProps = {
  $size?: HeadingSizeUnion
}

const headingFontSize =
  (tagName: HeadingSizeUnion) =>
  ({ $size }: HeadingProps) => css`
    margin: 1rem 0;
    font-weight: ${() => (tagName === 'h1' ? '600' : '400')};
    font-size: ${({ theme: { fontSize } }) => fontSize[$size || tagName]};
    line-height: ${({ theme: { lineHeight } }) => lineHeight[$size || tagName]};
  `

export const H1 = styled.h1<HeadingProps>`
  ${headingFontSize('h1')}
`

export const H2 = styled.h2<HeadingProps>`
  ${headingFontSize('h2')}
`

export const H3 = styled.h3<HeadingProps>`
  ${headingFontSize('h3')}
`

export const H4 = styled.h4<HeadingProps>`
  ${headingFontSize('h4')}
`

export const H5 = styled.h5<HeadingProps>`
  ${headingFontSize('h5')}
`

export const H6 = styled.h6<HeadingProps>`
  ${headingFontSize('h6')}
`
