/* eslint-disable sort-keys */
export const routePaths: Record<string, string> = {
  home: '/',
  assets: '/assets',
  account: '/account',
  assetsAll: '/assets/all',
  assetsMyVehicles: '/assets/my',
  assetsBridged: '/assets/bridged',
  assetsPortableUnits: '/assets/ptu',
  assetsShared: '/assets/shared',
  share: '/share',
  shareActive: '/share/active',
  shareActiveAll: '/share/active?filter=all',
  shareActiveInbound: '/share/active?filter=borrowed',
  shareActiveOutbound: '/share/active?filter=lent',
  shareActiveOnward: '/share/active?filter=onward',
  shareArchive: '/share/history',
  mapOverview: '/map',
  contacts: '/contacts',
  login: '/login',
  forgotPassword: '/password/forgot',
  resetPassword: '/password/reset/[uid]/[token]',
  error: '/404',
  expiredUrl: '/expired-url',
} as const

export const unRestrictedRoutes = [
  routePaths.login,
  routePaths.forgotPassword,
  routePaths.resetPassword,
  routePaths.error,
  routePaths.expiredUrl,
] as const

export const collapsedMenuRoutes = [routePaths.mapOverview]
