export const defaultPageTitle = 'Bridge | GoMetro'

export enum LoginStatesValues {
  LOADING = 'loading',
  AUTHENTICATED = 'authenticated',
  UNAUTHENTICATED = 'unauthenticated',
}

export type LoginStates =
  | LoginStatesValues.AUTHENTICATED
  | LoginStatesValues.LOADING
  | LoginStatesValues.UNAUTHENTICATED

export enum UnrestrictedEnpoints {
  CREATE = 'v1/auth/jwt/create/',
  REFRESH = 'v1/auth/jwt/refresh/',
}

export enum ThemeDirection {
  RTL = 'rtl',
  LTR = 'ltr',
}

export const DATE_FORMAT = 'DD/MM/YYYY'
export const DATE_FORMAT_API = 'YYYY-MM-DD'
export const SHORT_DATE_FORMAT = 'DD MMM, YY'
export const DATE_TIME_FORMAT = 'h:mmA DD/MM/YYYY'

export const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

export const GET_ALL_LIMIT = 9999
