import { ReactNode, useMemo } from 'react'
import Head from 'next/head'
import { useRouter } from 'next/router'
import { useSession } from 'next-auth/react'

import { collapsedMenuRoutes, unRestrictedRoutes } from '@/lib/core/constants/routes'
import { LoginStatesValues } from '@/lib/core/constants/constants'
import { Loader } from '@/lib/core/components/Loader'
import { LogoSmall } from '@/lib/core/components/Logo'
import { LoadingWrapper, Main, PageWrapper, TopBar } from '@/lib/core/components/Layout/Layout.styles'
import { LayoutContextProvider, Sidebar, TopbarMenu } from '@/lib/core/components/Layout'

type LayoutProps = {
  children: ReactNode
}

export const Layout = ({ children }: LayoutProps) => {
  const { route } = useRouter()
  const { data: session, status } = useSession({
    required: !unRestrictedRoutes.includes(route),
  })
  const isLoading = status === LoginStatesValues.LOADING
  const isMenuExpanded = useMemo(() => !collapsedMenuRoutes.includes(route), [route])

  if (isLoading) {
    return (
      <LoadingWrapper>
        <Loader />
      </LoadingWrapper>
    )
  }

  if (!session || unRestrictedRoutes.includes(route)) {
    return <>{children}</>
  }

  return (
    <LayoutContextProvider value={{ isMenuExpanded }}>
      <PageWrapper $isMenuExpanded={isMenuExpanded}>
        <Head>
          <title>Bridge - GoMetro</title>
        </Head>
        <TopBar>
          <LogoSmall />
          <TopbarMenu />
        </TopBar>
        <Sidebar />
        <Main>{children}</Main>
      </PageWrapper>
    </LayoutContextProvider>
  )
}
