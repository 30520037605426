import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk'
import { ReactNode } from 'react'

import isServer from '@/utils/isServer'

const ServerProxy = ({children}: {children: ReactNode}) => {
  return <>{children}</>
}

export type Settings = Partial<Parameters<typeof asyncWithLDProvider>[0]>
export const withRemoteConfigProvider = (settings?: Settings): ReturnType<typeof asyncWithLDProvider> =>
  isServer ? Promise.resolve(ServerProxy) : asyncWithLDProvider({
    clientSideID: process.env.NEXT_PUBLIC_LAUNCHDARKLY_CLIENT_ID!,
    ...settings,
  })
