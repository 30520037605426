import { DefaultIconProps } from '@/lib/core/interfaces/common'

export const FolderClosedIcon = ({ color = 'currentColor', size = '16' }: DefaultIconProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.66683 13.3333H13.3335C13.6871 13.3333 14.0263 13.1929 14.2763 12.9428C14.5264 12.6928 14.6668 12.3536 14.6668 12V5.33333C14.6668 4.97971 14.5264 4.64057 14.2763 4.39052C14.0263 4.14048 13.6871 4 13.3335 4H8.04683C7.82722 3.99886 7.61129 3.9435 7.41823 3.83883C7.22516 3.73415 7.06095 3.58341 6.94016 3.4L6.3935 2.6C6.27271 2.41659 6.10849 2.26585 5.91543 2.16117C5.72237 2.0565 5.50644 2.00114 5.28683 2H2.66683C2.31321 2 1.97407 2.14048 1.72402 2.39052C1.47397 2.64057 1.3335 2.97971 1.3335 3.33333V12C1.3335 12.7333 1.9335 13.3333 2.66683 13.3333Z"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M1.3335 6.66675H14.6668" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
  </svg>
)
