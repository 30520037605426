/* eslint-disable no-bitwise, no-mixed-operators */

/**
 * Docs and credits:
 * https://github.com/PimpTrizkit/PJs/wiki/12.-Shade,-Blend-and-Convert-a-Web-Color-(pSBC.js)#----update---version-2-with-blending---
 *
 * @param color
 * Just a color in hex value i.e. '#eeeeee'
 *
 * @param percent
 * You can provide values from 1 to -1
 * It can be a float value i.e. 0.5 or -0.5
 *
 * Values > 0 are equal to brighter shade of our base colour
 * values < 0 are equal to darker shade of our base colour
 *
 */
export const shadeHexColor = (color: string, percent: number) => {
  const f = parseInt(color.slice(1), 16)
  const t = percent < 0 ? 0 : 255
  const p = percent < 0 ? percent * -1 : percent
  const R = f >> 16
  const G = (f >> 8) & 0x00ff
  const B = f & 0x0000ff

  return `#${(0x1000000 + (Math.round((t - R) * p) + R) * 0x10000 + (Math.round((t - G) * p) + G) * 0x100 + (Math.round((t - B) * p) + B)).toString(16).slice(1)}`
}

export const stringToColor = (string: string, lightColor = '#fff', darkColor = '#000') => {
  let hash = 0

  for (let i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash)
  }

  let background = '#'
  let rgbSum = 0

  for (let i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff

    rgbSum += value
    background += `00${value.toString(16)}`.substr(-2)
  }

  return {
    background,
    color: rgbSum / 3 < 180 ? lightColor : darkColor,
  }
}
