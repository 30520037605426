import { ElementType } from 'react'

import { MergeElementProps } from '@/lib/core/interfaces/common'

import {
  LoaderCircularCircle,
  LoaderCircularIcon,
  LoaderLinearBackground,
  LoaderLinearLoader,
  LoaderWrapper,
} from './Loader.styles'

interface LoaderCustomProps {
  isLinear?: boolean
  color?: string
  'data-testid'?: string
  WrapperComponent?: ElementType
}

export type LoaderProps = Omit<MergeElementProps<'div', LoaderCustomProps>, 'children'>

export const LoaderComponent = ({
  isLinear = false,
  WrapperComponent = LoaderWrapper,
  color,
  ...props
}: LoaderProps) => (
  <WrapperComponent {...props} isLinear={isLinear} role="progressbar">
    {isLinear ? (
      <>
        <LoaderLinearLoader />
        <LoaderLinearBackground />
      </>
    ) : (
      <LoaderCircularIcon viewBox="22 22 44 44">
        <LoaderCircularCircle
          color={color}
          cx="44"
          cy="44"
          r="20.2"
          fill="none"
          strokeWidth="3.6"
        />
      </LoaderCircularIcon>
    )}
  </WrapperComponent>
)
