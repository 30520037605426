import { LDMultiKindContext } from 'launchdarkly-js-sdk-common'
import { useLDClient } from 'launchdarkly-react-client-sdk'
import { lazy, ReactNode, useCallback, useEffect, useState } from 'react'

import { useGetUser } from '@/feature/auth'
import { withRemoteConfigProvider } from '@/feature/feature-flags'
import { captureError } from '@/utils/captureError'

const FeatureFlagProviderLD = lazy(async () => ({ default: await withRemoteConfigProvider() }))

const SetupUser = ({ children }: { children: ReactNode }) => {
  const { data: userData, isLoading, error } = useGetUser()
  const ldClient = useLDClient()

  const [renderChildren, setRenderChildren] = useState(false)

  const loadUser = useCallback(async () => {
    if (ldClient) {
      if (userData?.organization) {
        const newContext: LDMultiKindContext = {
          kind: 'multi',
          org: {
            key: `${userData.organization.id}`,
            name: userData.organization.name,
          },
          user: {
            key: `${userData.id}`,
            name: userData.firstName,
            email: userData.email,
          },
        }

        await ldClient.identify(newContext)
      } else {
        await ldClient.identify({ anonymous: true })
      }
    }
  }, [ldClient, userData])

  useEffect(() => {
    loadUser()
      .then(() => setRenderChildren(true))
      .catch(captureError())
  }, [loadUser])

  return (!isLoading || error !== undefined) && renderChildren && children
}

export type AnalyticsProviderProps = {
  children: ReactNode
}
export const FeatureFlagProvider = ({ children }: AnalyticsProviderProps) => {
  return (
    <FeatureFlagProviderLD>
      <SetupUser>{children}</SetupUser>
    </FeatureFlagProviderLD>
  )
}
