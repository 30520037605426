import { ActionReducerMapBuilder, createReducer } from '@reduxjs/toolkit'

import { RequestStatus } from '@/lib/core/interfaces/common'

import { AccountState } from '@/lib/features/Account/interfaces'
import { getMyColleagues } from '@/lib/features/Account/store/actions'
import { getOrganizationCharges, getSharesCharges } from '@/lib/features/SmartShares/store'

export const initialState: AccountState = {
  myColleagues: {
    data: {
      count: 0,
      next: null,
      previous: null,
      results: [],
    },
    error: null,
    status: RequestStatus.IDLE,
  },
  organizationCharges: {
    data: [],
    error: null,
    status: RequestStatus.IDLE,
  },
  shareCharges: {
    data: {
      count: 0,
      next: null,
      previous: null,
      results: [],
    },
    error: null,
    status: RequestStatus.IDLE,
  },
}

export const accountReducer = createReducer(
  initialState,
  (builder: ActionReducerMapBuilder<AccountState>) => {
    builder
      .addCase(getMyColleagues.pending, (state) => {
        state.myColleagues.status = RequestStatus.LOADING
      })
      .addCase(getMyColleagues.fulfilled, (state, { payload }) => {
        state.myColleagues.status = RequestStatus.SUCCEEDED
        state.myColleagues.data = payload
        state.myColleagues.error = null
      })
      .addCase(getMyColleagues.rejected, (state, { payload }) => {
        state.myColleagues.status = RequestStatus.FAILED
        state.myColleagues.error = payload
      })
      .addCase(getSharesCharges.pending, (state) => {
        state.shareCharges.status = RequestStatus.LOADING
      })
      .addCase(getSharesCharges.fulfilled, (state, { payload }) => {
        state.shareCharges.status = RequestStatus.SUCCEEDED
        state.shareCharges.data = payload
        state.shareCharges.error = null
      })
      .addCase(getSharesCharges.rejected, (state, { payload }) => {
        state.shareCharges.status = RequestStatus.FAILED
        state.shareCharges.error = payload
      })
      .addCase(getOrganizationCharges.pending, (state) => {
        state.organizationCharges.status = RequestStatus.LOADING
      })
      .addCase(getOrganizationCharges.fulfilled, (state, { payload }) => {
        state.organizationCharges.status = RequestStatus.SUCCEEDED
        state.organizationCharges.data = payload
        state.organizationCharges.error = null
      })
      .addCase(getOrganizationCharges.rejected, (state, { payload }) => {
        state.organizationCharges.status = RequestStatus.FAILED
        state.organizationCharges.error = payload
      })
  },
)
