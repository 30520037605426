import { ReactNode } from 'react'
import { ThemeProvider } from 'styled-components'

import { GlobalStyle } from './globalStyles'

import { getTheme, ModifyThemeFunctionType, NormalizeStyles, theme } from '.'

type ThemeProvidersType = {
  children: ReactNode
  rootTheme?: ModifyThemeFunctionType
}

export const ThemeProviders = ({ children, rootTheme = () => theme }: ThemeProvidersType) => (
  <ThemeProvider theme={getTheme(rootTheme)}>
    <>
      <NormalizeStyles />
      <GlobalStyle />
      {children}
    </>
  </ThemeProvider>
)
