import { createSelector } from '@reduxjs/toolkit'

import { RootState } from '@/lib/core/interfaces/store'

import { AccountState } from '@/lib/features/Account/interfaces'

const featureStateSelector = (state: RootState): AccountState => state.account

export const getMyColleaguesSelector = createSelector(
  featureStateSelector,
  (state) => state.myColleagues,
)

export const getOrganizationChargesSelector = createSelector(
  featureStateSelector,
  (state) => state.organizationCharges,
)

export const getOrganizationTotalChargesSelector = createSelector(
  getOrganizationChargesSelector,
  ({ data }) =>
    data.reduce(
      (acc, { amount, amountCurrency }) => ({
        ...acc,
        [amountCurrency as string]: (acc[amountCurrency as string] || 0) + parseFloat(amount),
      }),
      {} as { [key: string]: number },
    ),
)

export const getSharesChargesSelector = createSelector(
  featureStateSelector,
  (state) => state.shareCharges,
)

export const getChargesSummarySelector = createSelector(getSharesChargesSelector, (state) =>
  state.data.results.reduce(
    (acc, { totalCost, totalSmartShares, currency }) => ({
      costAmount: {
        ...acc.costAmount,
        [currency as string]: (acc.costAmount[currency as string] || 0) + parseFloat(totalCost),
      },
      sharesAmount: acc.sharesAmount + totalSmartShares,
    }),
    {
      costAmount: {} as { [key: string]: number },
      sharesAmount: 0,
    },
  ),
)
